import "./BasketPopup.scss";
import {Link} from "react-router-dom";
import BasketItemPopup from "./BasketItemPopup";

const BasketPopup = () => {
    // Directly initialize state with items from local storage
    const storedItems = localStorage.getItem('basket');
    const items = storedItems ? JSON.parse(storedItems) : [];
    const lastItem = items[items.length - 1];
    const totalPrice = items.reduce((acc, item) => acc + parseFloat(item.price), 0);

    const basketContent = (
        <div className="basket-popup-content">
            <BasketItemPopup
                key={0}
                itemId={0}
                imgIndex={lastItem.colorIndex}
                title={lastItem.productName}
                price={lastItem.price}
            />
            <p className="popup-total-price">Całkowita wartość produktów: {totalPrice}<small>,00 zł</small></p>
        </div>
    );

    const noItemsInfo = (
        <div className="content basket">
            <div className="column-left">
                <h2>Twoje zamówienie</h2>
                <p>Nie masz nic w koszyku!</p>
                <p><Link to="/konfigurator">Zachęcam do przeglądania produktów, na pewno znajdziesz coś dla siebie!</Link></p>
            </div>
        </div>
    );

    return items.length > 0 ? basketContent : noItemsInfo;
};

export default BasketPopup;