import React from 'react';
import './Cennik.scss';
import {Helmet} from "react-helmet";

const PricelistDogs = () => {
    return (
        <div className="content pricelist">
            <Helmet>
                <title>Smycze i obroże ręcznie robione</title>
                <meta name="description"
                      content="Smycze, obroże i sprzęt dla Twojego pupila"/>
            </Helmet>
            <h1>Cennik: smycze i obroże</h1>
            <section className="content-section center">
                <h2>Każdy produkt mogę dopasować do indywidualnych potrzeb i wymagań!</h2>
                <p>Listę produktów z podstawowej oferty znajdziesz poniżej.</p>
                <p>Zachęcam do kontaktu i zadawania pytań!</p>
            </section>
            <hr/>
            <section className="content-section">
                <h1>Smycz z liny statycznej</h1>
                <p>Ze skórzanym wykończeniem i srebrnym karabińczykiem spustowym, jednopunktowa regulacja długości:</p>
                <table className="content-table short">
                    <thead>
                    <tr>
                        <th></th>
                        <th>Cena</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>3 metry</td>
                        <td>120zł</td>
                    </tr>
                    <tr>
                        <td>5 metrów</td>
                        <td>160zł</td>
                    </tr>
                    </tbody>
                </table>
                <ul>
                    <li>Na zamówienie dowolna długość powyżej 5 metrów w cenie 20zł za metr</li>
                    <li>Na zamówienie możliwe indywidualne dobranie koloru i typu karabińczyka w cenie od 15 do 30zł
                    </li>
                </ul>
            </section>
            <hr/>
            <section className="content-section">
                <h1>Smycz z liny dynamicznej</h1>
                <p>O podwyższonej wytrzymałości, ze skórzanym wykończeniem i srebrnym karabińczykiem spustowym, jednopunktowa regulacja długości:</p>
                <table className="content-table short">
                    <thead>
                    <tr>
                        <th></th>
                        <th>Cena</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>3 metry</td>
                        <td>140zł</td>
                    </tr>
                    <tr>
                        <td>5 metrów</td>
                        <td>180zł</td>
                    </tr>
                    </tbody>
                </table>
                <ul>
                    <li>Na zamówienie dowolna długość powyżej 5 metrów w cenie 20zł za metr</li>
                    <li>Na zamówienie możliwe indywidualne dobranie koloru i typu karabińczyka w cenie od 15 do 30zł
                    </li>
                </ul>
            </section>
            <hr/>
            <section className="content-section">
                <h1>Smycz ringówka</h1>
                <ul>
                    <li>skórzane elementy wykończenia, elementy metalowe w kolorystyce do wyboru: srebro, złoto, różowe złoto, tęczowy, czarny</li>
                    <li>Długość od 90 do 120cm w stałej cenie 90zł</li>
                </ul>
            </section>
            <hr/>
            <section className="content-section">
                <h1>Obroża z liny ze skórzanym wykończeniem: 50zł</h1>
            </section>
            <hr/>
            <section className="content-section">
                <h1>Personalizacja</h1>
                <p>Każdy produkt może być spersonalizowany według Twoich oczekiwań:</p>
                <ul>
                    <li>metka z imieniem psa: 30zł</li>
                    <li>ozdobne tłoczenia na skórzanych elementach: 30zł</li>
                </ul>
            </section>
            <hr/>
            <section className="content-section">
                <h1>Koszty dostawy</h1>
                <ul>
                    <li>Dostawa kurierem InPost lub do paczkomatu: 20zł</li>
                    <li>Możliwy odbiór osobisty na terenie Poznania</li>
                </ul>
            </section>
        </div>
    );
}

export default PricelistDogs;