import InpostGeowidget from "../../Utility/InpostGeowidget";
import {useEffect, useState} from "react";
import DeliveryForm from "./DeliveryForm";

const DeliveryOptions = ({callback, baseDeliveryCost}) => {

    const INPOST = "paczkomat InPost";
    const DIRECT = "kurier";
    const SELF = "odbiór osobisty";

    const [isInpostWidgetVisible, setInPostWidgetVisibility] = useState(false);
    const [paczkomatName, setPaczkomatName] = useState("nie wybrano");

    //TODO: To refactor: nie miałem czasu na wydzielenie componentu-matki, więc tutaj są zbublowane
    // state'y dotyczące delivery - to samo jest w Checkout.js
    const [deliveryType, setDeliveryType] = useState(INPOST);
    const [deliveryCost, setDeliveryCost] = useState(baseDeliveryCost);
    const [deliveryAddress, setDeliveryAddress] = useState("");

    useEffect(() => {
        if (isInpostWidgetVisible) {
            // Disable scroll
            document.body.style.overflow = 'hidden';
        } else {
            // Enable scroll
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isInpostWidgetVisible]);

    useEffect(() => {
        callback(deliveryType, deliveryCost, deliveryAddress)
    }, [deliveryType, deliveryCost, deliveryAddress, callback])

    const onPointPicked = point => {
        setDeliveryAddress(point);
        setPaczkomatName(point);
        setDeliveryCost(baseDeliveryCost);
        setInPostWidgetVisibility(false);
    }

    const changeDeliveryType = (type) => {
        setDeliveryType(type);
        if (type === SELF) {
            setDeliveryCost(0)
        } else {
            setDeliveryCost(20);
        }

        if (type === INPOST) {
            setDeliveryAddress(paczkomatName);
        }
    }

    const saveDeliveryAddress = (deliveryAddress) => {
        if (deliveryType === DIRECT) {
            setDeliveryAddress(deliveryAddress);
        }
    }

    return (
        <div className="checkout-delivery">
            <h3>Dostawa</h3>
            <div className="checkout-delivery-type">
                <div
                    className={`inpost-button ${deliveryType === INPOST ? "checked" : ""}`}
                    onClick={() => changeDeliveryType(INPOST)}>
                    Paczkomaty InPost
                </div>
                <div className={`dpd-button ${deliveryType === DIRECT ? "checked" : ""}`}
                     onClick={() => changeDeliveryType(DIRECT)}>Kurier
                </div>
                <div className={`pickup-button ${deliveryType === SELF ? "checked" : ""}`}
                     onClick={() => changeDeliveryType(SELF)}>Odbiór osobisty
                </div>
            </div>
            <div className={`inpost-container ${deliveryType === INPOST ? "visible" : "invisible"}`}>
                <div className="inpost-chooser">
                    <p className="paczkomat-title">Wybrany paczkomat:</p>
                    <p className="paczkomat-name" onClick={() => {
                        setInPostWidgetVisibility(true)
                    }}>{paczkomatName}</p>
                </div>
                <p className='delivery-cost-info'><strong>Koszt wysyłki:</strong> {baseDeliveryCost},00zł</p>
                <div className={`inpost-widget-container ${isInpostWidgetVisible ? "visible" : "invisible"}`}
                    onClick={() => {setInPostWidgetVisibility(false)}}>
                    <div className="close-button">Zamknij <strong>X</strong></div>
                    <InpostGeowidget callback={onPointPicked}/>
                </div>
            </div>
            <div className={`dpd-container ${deliveryType === DIRECT ? "visible" : "invisible"}`}>
                <p className="direct-info"><strong>Dostawa:</strong> kurier InPost</p>
                <p className='delivery-cost-info'><strong>Koszt wysyłki:</strong> {baseDeliveryCost},00zł</p>
                <DeliveryForm callback={saveDeliveryAddress} />
            </div>
            <div className={`pickup-container ${deliveryType === SELF ? "visible" : "invisible"}`}>
                <p className="pickup-info"><strong>Odbiór osobisty:</strong> Poznań</p>
                <p className="pickup-info">Możliwość odbioru w Poznaniu, na os. Jana III Sobieskiego lub po kontakcie
                telefonicznym mogę dostarczyć zamówienie w obrębie Piątkowa i okolic.</p>
                <p className='delivery-cost-info'><strong>Koszt odbioru:</strong> 0,00zł</p>
            </div>
        </div>
    );
}
export default DeliveryOptions;