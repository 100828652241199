import React, {useState} from 'react';
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';
import "./CookieDisclaimer.scss";

const CookieDisclaimer = () => {
    const [popupClosed, setPopupClosed] = useState(false);

    const handleAccept = () => {
        Cookies.set('cookieConsent', 'accepted', { expires: 365 });
        setPopupClosed(true);
    };

    return !popupClosed ? (
        <div className="cookie-disclaimer">
            <p>Jak koń bez marchewki, tak my z cookies – nie możemy bez nich żyć! Przeglądając stronę wyrażasz zgodę na
                ich użycie. <Link to="/prywatnosc">Skocz po szczegóły.</Link></p>
            <div className="cookie-buttons">
                <p className="accept" onClick={handleAccept}>Akceptuję to!</p>
                <p className="decline" onClick={() => {setPopupClosed(true)}}>Nie zgadzam się!</p>
            </div>
        </div>
    ) : "";
}

export default CookieDisclaimer;
