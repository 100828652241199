import "./Kontakt.css";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

function Kontakt() {
    return (
        <div className="contact-container">
            <Helmet>
                <title>Ręcznie robiony sprzęt dla koni i psów - kontakt do Corinny</title>
                <meta name="description" content="Napisz do Corinny i zapytaj o liny, cordeo, uwiązy, wodze linowe dla konia" />
            </Helmet>
            <section>
                <h1>Jak złożyć zamówienie?</h1>
                <p>Skorzystaj z prostego w użyciu <Link to="/konfigurator">konfiguratora</Link> lub po prostu napisz wiadomość na adres email: <a
                    href="mailto:kontakt@corinnyliny.pl">kontakt@corinnyliny.pl</a></p>
                <p>W wiadomość określ jaki sprzęt Cię interesuje, wszelkie informacje możesz uzyskać
                    w <Link to="/cennik">cenniku</Link>.</p>
                <p><strong>Haltery są robione na miarę!</strong> Wysyłając zamówienie na halter koniecznie poinformuj
                    nas o jego wymiarach.</p>
                <p>Informacje o tym jak wymierzyć halter znajdziesz klikając <Link to="/dopasowanie">tutaj</Link>.</p>

                <p>W razie pytań <strong>zachęcamy do kontaktu telefonicznego</strong>: +48 602 462 444</p>
            </section>
            <section>
                <h1>Jaki jest czas oczekiwania?</h1>
                <p>Czas oczekiwania to <strong>od 1 do 3 tygodni</strong>, w zależności od tego czy materiały
                    na zamówienie dostępne są od ręki czy występuje konieczność zamówienia materiałów u producenta.</p>
                <p>Po każdym zamówieniu informujemy o orientacyjnej dacie dostawy produktu.</p>
            </section>
        </div>
    );
}

export default Kontakt;