import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import './DeliveryForm.scss';

const DeliveryForm = ({callback}) => {
    const {register, watch, formState: {errors}} = useForm();

    const formData = watch();

    useEffect(() => {
        const deliveryInfo = formData.street + " "
            + formData.houseNumber + ", " + formData.postcode + " " + formData.city;

        callback(deliveryInfo);
    }, [formData, callback]);

    return (
        <form className="delivery-form">
            <table>
                <tbody>
                <tr>
                    <th><label htmlFor="city">Miasto:</label></th>
                    <td>
                        <input
                            id="city"
                            name="city"
                            {...register('city', {required: 'Pole wymagane'})}
                            className={errors.city ? 'error' : ''}
                        />
                        {errors.city && <span className="error-message">{errors.city.message}</span>}
                    </td>
                </tr>
                <tr>
                    <th><label htmlFor="postcode">Kod pocztowy:</label></th>
                    <td>
                        <input
                            id="postcode"
                            name="postcode"
                            {...register('postcode', {
                                required: 'Pole wymagane',
                                pattern: {
                                    value: /^[0-9]{2}-[0-9]{3}$/,
                                    message: 'Nieprawidłowy format kodu pocztowego',
                                },
                            })}
                            className={errors.postcode ? 'error' : ''}
                        />
                        {errors.postcode && <span className="error-message">{errors.postcode.message}</span>}
                    </td>
                </tr>
                <tr>
                    <th><label htmlFor="street">Ulica:</label></th>
                    <td>
                        <input
                            id="street"
                            name="street"
                            {...register('street', {required: 'Pole wymagane'})}
                            className={errors.street ? 'error' : ''}
                        />
                        {errors.street && <span className="error-message">{errors.street.message}</span>}
                    </td>
                </tr>
                <tr>
                    <th><label htmlFor="houseNumber">Numer lokalu:</label></th>
                    <td>
                        <input
                            id="houseNumber"
                            name="houseNumber"
                            {...register('houseNumber', {required: 'Pole wymagane'})}
                            className={errors.houseNumber ? 'error' : ''}
                        />
                        {errors.houseNumber && <span className="error-message">{errors.houseNumber.message}</span>}
                    </td>
                </tr>
                </tbody>
            </table>
        </form>
    );
};

export default DeliveryForm;
