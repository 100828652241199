import React from 'react';
import "./PrivacyPolicy.scss"

const PrivacyPolicy = () => (
    <div className="content privacy-policy">
        <h1>Polityka Prywatności</h1>
        <h2>1. Wstęp</h2>
        <p>Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych użytkowników serwisu
            www.corinnyliny.pl.</p>

        <h2>2. Administrator danych</h2>
        <p>Administrator danych osobowych: Necessary Software, adres: os. Jana Sobieskiego 2, 60-688 Poznań, e-mail:
            tk@tomaszkonieczny.pl.</p>

        <h2>3. Cel przetwarzania danych</h2>
        <p>Dane osobowe użytkowników przetwarzane są w celu realizacji zamówień złożonych przez klientów.</p>

        <h2>4. Rodzaje przetwarzanych danych</h2>
        <p>Przetwarzamy następujące kategorie danych osobowych:
            <ul>
                <li>Adres e-mail</li>
                <li>Adres dostawy</li>
                <li>Numer telefonu</li>
                <li>Historia zamówień</li>
            </ul>
        </p>

        <h2>5. Odbiorcy danych</h2>
        <p>Dane osobowe użytkowników mogą być przekazywane dostawcom usług, z których korzysta Administrator, w celach
            technicznych i organizacyjnych, np. firmy hostingowe, firmy kurierskie.</p>

        <h2>6. Prawa użytkownika</h2>
        <p>Użytkownik ma prawo do dostępu do swoich danych, sprostowania, usunięcia lub ograniczenia przetwarzania,
            prawo do przenoszenia danych, prawo do wniesienia sprzeciwu, prawo do cofnięcia zgody w dowolnym momencie
            bez wpływu na zgodność z prawem przetwarzania. W celu zgłoszenia zmian związanych z danymi użytkownik
            powinien skontaktować się poprzez email kontakt@corinnyliny.pl</p>

        <h2>7. Pliki cookies</h2>
        <p>Serwis używa plików cookies w celu poprawy komfortu korzystania ze strony, analizy ruchu na stronie oraz
            celów reklamowych. Użytkownik może samodzielnie zarządzać plikami cookies, zmieniając ustawienia swojej
            przeglądarki.</p>

        <h2>8. Zmiany w polityce prywatności</h2>
        <p>Administrator zastrzega sobie prawo do zmian w polityce prywatności, o czym użytkownicy zostaną poinformowani
            z wyprzedzeniem.</p>

        <h2>9. Dane kontaktowe</h2>
        <p>W sprawach dotyczących przetwarzania danych osobowych prosimy o kontakt: kontakt@corinnyliny.pl.</p>
    </div>
);

export default PrivacyPolicy;
