import "./Intro.scss";
import {Link} from "react-router-dom";

const DogsIntro = () => {
    return (
        <div className="poster products-animals">
            <Link className="box smycz_blue"  to={"/dlapsa/smycz-dynamiczna"}><p className="dog">Smycze dynamiczne</p></Link>
            <Link className="box smycz_red" to={"/dlapsa/smycz-statyczna"}><p className="dog">Smycze statyczne</p></Link>
            <Link className="box smycz_blue" to={"/dlapsa/cennik"}><p className="dog">Smycze ringówki</p></Link>
            <Link className="box obroza" to={"/dlapsa/cennik"}><p className="dog">Obroże</p></Link>
        </div>
    );

}

export default DogsIntro;