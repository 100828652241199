import "../Products.scss"
import RopesDescription from "./RopesDescription";
import Picker from "../../Galeria/Picker/Picker";
import RopeModel from "./RopeModel";
import {useState} from "react";
import AddToBasket from "../basket/AddToBasket";
import {Helmet} from "react-helmet";

const Ropes = () => {

    const [rope] = useState(new RopeModel());
    const [price, setPrice] = useState(rope.calculatePrice());

    const handleThicknessChange = (e) => {
        rope.setThickness(e.target.value)
        if (price !== rope.calculatePrice()) {
            setPrice(rope.calculatePrice());
        }
    };

    const handleLengthChange = (e) => {
        rope.setLength(e.target.value)
        if (price !== rope.calculatePrice()) {
            setPrice(rope.calculatePrice());
        }
    };

    const handleRopeColorChange = (index) => {
        rope.setColorIndex(index);
    }

    const handlePopperChange = e => {
        const value = e.target.value;
        rope.setPopperType(value);
        setPrice(rope.calculatePrice());
    }

    const handlePopperDetailsInput = e => {
        const value = e.target.value;
        rope.setPopperDetails(value);
    }

    const handleCarabinerChange = e => {
        const v = e.target.value;
        rope.setCarabiner(v);

        setPrice(rope.calculatePrice());
    }

    return (
        <div className="content product">
            <Helmet>
                <title>Lina treningowa wysokiej jakości - Corinny Liny</title>
                <meta name="description" content="Profesjonalna lina do treningu konia, ręcznie robiona, wysokiej jakości" />
                <link rel="canonical" href="https://corinnyliny.pl/dlakonia/liny" />
            </Helmet>
            <div className="column column-left">
                <RopesDescription/>
                <div className="thickness box">
                    <p>Wybierz grubość:</p>
                    <div className="select-picker mobile-only">
                        <select id="thicknessSelect" onChange={handleThicknessChange}>
                            <option value="14" defaultChecked="true">14mm</option>
                            <option value="12" onChange={handleThicknessChange}>12mm</option>
                        </select>
                    </div>
                    <div className="radio-picker">
                        <label>
                            <input type="radio" id="14mm" value="14" name="thickness" defaultChecked="true"
                                   onChange={handleThicknessChange}/>
                            <span>14mm</span>
                        </label>

                        <label>
                            <input type="radio" id="12mm" value="12" name="thickness" onChange={handleThicknessChange}/>
                            <span> 12mm</span>
                        </label>
                    </div>
                </div>
                <div className="rope-length box">
                    <p>Wybierz długość:</p>
                    <div className="select-picker mobile-only">
                        <select id="ropeLengthSelect" onChange={handleLengthChange}>
                            <option value="350">3,5m</option>
                            <option value="450">4,5m</option>
                            <option value="650">6,5m</option>
                            <option value="550">5,5m</option>
                            <option value="750">7,5m</option>
                        </select>
                    </div>
                    <div className="radio-picker">
                        <label>
                            <input type="radio" id="350mm" value="350" name="rope-length" onChange={handleLengthChange}
                                   defaultChecked="true"/>
                            <span>3,5m</span>
                        </label>

                        <label>
                            <input type="radio" id="450mm" value="450" onChange={handleLengthChange}
                                   name="rope-length"/>
                            <span>4,5m</span>
                        </label>

                        <label>
                            <input type="radio" id="550mm" value="550" onChange={handleLengthChange}
                                   name="rope-length"/>
                            <span>5,5m</span>
                        </label>

                        <label>
                            <input type="radio" id="650mm" value="650" onChange={handleLengthChange}
                                   name="rope-length"/>
                            <span>6,5m</span>
                        </label>

                        <label>
                            <input type="radio" id="750mm" value="750" onChange={handleLengthChange}
                                   name="rope-length"/>
                            <span>7,5m</span>
                        </label>
                    </div>
                </div>

                <div className="rope-addons box">
                    <p className="options-title">Wybierz elementy opcjonalne</p>
                    <p className="radio-picker-title">Karabinek:</p>
                    <div className="options-picker">
                        <label>
                            <input type="radio" id="carabiner" onChange={handleCarabinerChange} value="none"
                                   defaultChecked="true"
                                   name="addon"/>
                            <span>bez karabinka</span>
                        </label>
                        <label>
                            <input type="radio" id="carabiner" onChange={handleCarabinerChange} value="standard"
                                   name="addon"/>
                            <span>standardowy</span>
                        </label>
                        <label>
                            <input type="radio" id="carabiner" onChange={handleCarabinerChange} value="custom"
                                   name="addon"/>
                            <span>dobrany indywidualnie</span>
                        </label>
                    </div>

                    <p className="radio-picker-title">Skórzany popper:</p>
                    <div className="options-picker">
                        <label>
                            <input type="radio" id="popper" value="plain" onChange={handlePopperChange}
                                   name="addon-popper"/>
                            <span>bez zdobień</span>
                        </label>

                        <label>
                            <input type="radio" id="popper" onChange={handlePopperChange} value="graver"
                                   name="addon-popper"/>
                            <span>grawer</span>
                        </label>

                        <label>
                            <input type="radio" id="popper" onChange={handlePopperChange} value="individual"
                                   name="addon-popper"/>
                            <span>artystyczny</span>
                        </label>
                    </div>
                </div>
                <div className={`popper-detail ${rope.getPopperType() === "graver" ? "visible" : "invisible"}`}>
                    <p className="popper-detail-desc">Podaj treść grawera:</p>
                    <input id="popper-graver-text"
                           onChange={handlePopperDetailsInput}
                           type="text" className="graver-input" placeholder="Twój napis..." maxLength={20} />
                </div>
                <div className={`popper-detail ${rope.getPopperType() === "individual" ? "visible" : "invisible"}`}>
                    <p className="popper-detail-desc">Opisz swój pomysł/wizję:</p>
                    <textarea id="popper-individual-textarea"
                              onChange={handlePopperDetailsInput}
                              placeholder="Opisz w paru słowach co chciał(a)byś
                    umieścić na popperze. Może być to np. napis, wzór lub to i to. Może być to też jakaś inspiracja lub pomysł, który rozwinę dla Ciebie."/>
                </div>
            </div>
            <div className="column column-right">
                <div className="rope-color">
                    <Picker name="ropes" handler={handleRopeColorChange}/>
                </div>
                <AddToBasket item={rope}/>
            </div>
        </div>
    )
        ;

}

export default Ropes;