import React from 'react';
import { getAuth } from 'firebase/auth';
import Admin from "../Admin/Admin";
import AdminAuth from "../Admin/AdminAuth";

const PrivateRoute = () => {
    const auth = getAuth();
    const user = auth.currentUser;

    return user ? <Admin /> : <AdminAuth />;
};

export default PrivateRoute;