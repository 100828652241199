import {useEffect, useState} from "react";
import "./Checkout.scss";
import {Link, useNavigate} from "react-router-dom";
import CheckoutSummary from "./CheckoutSummary";
import DeliveryOptions from "./DeliveryOptions";

const Checkout = () => {
    const BASE_DELIVERY_COST = 20;

    // Directly initialize state with items from local storage
    const storedItems = localStorage.getItem('basket');
    const initialItems = storedItems ? JSON.parse(storedItems) : [];
    const [items] = useState(initialItems);
    const [totalPrice, setTotalPrice] = useState(0);
    const [email, setEmail] = useState("");
    const [buyerName, setBuyerName] = useState("");
    const [phone, setPhone] = useState("");
    const [policy, setPolicy] = useState("");
    const [errors, setErrors] = useState({});
    const [deliveryType, setDeliveryType] = useState("inpost");
    const [deliveryCost, setDeliveryCost] = useState(BASE_DELIVERY_COST);
    const [deliveryAddress, setDeliveryAddress] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        const sum = items.reduce((acc, item) => acc + parseFloat(item.price), 0);
        setTotalPrice(sum);
    }, [items]);

    const handleFormChange = e => {
        if (e.target.id === "email") {
            setEmail(e.target.value);
        } else if (e.target.id === "phone") {
            setPhone(e.target.value);
        } else if (e.target.id === "privacyPolicy") {
            setPolicy(e.target.checked);
        } else if (e.target.id === "buyerName") {
            setBuyerName(e.target.value);
        }
    }

    const checkout = async () => {
        errors.noPolicy = policy !== true;
        errors.noEmail = email.length === 0
        errors.noPhone = phone.length === 0;
        errors.noBuyerName = buyerName.length === 0;

        const newErrors = {...errors};
        setErrors(newErrors);

        if (!errors.noPolicy && !errors.noEmail && !errors.noPhone && !errors.noBuyerName) {
            const date = new Date().toLocaleString('pl-PL', {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour12: false
            });

            const order = {
                email: email,
                phone: phone,
                buyerName: buyerName,
                isPolicyAccepted: policy,
                basket: {items},
                date: date,
                totalPrice: totalPrice + deliveryCost,
                deliveryDetails: {
                    deliveryType: deliveryType,
                    deliveryCost: deliveryCost,
                    deliveryAddress: deliveryAddress
                }
            }

            navigate('/zaplac', {state: {order}});
        }
    }

    const changeDeliveryOptions = (deliveryType, deliveryCost, deliveryAddress) => {
        setDeliveryType(deliveryType);
        setDeliveryCost(deliveryCost);
        setDeliveryAddress(deliveryAddress);
    }

    return (
        <div className="content checkout">
            <div className="checkout-form">
                <h3>Dane do zamówienia</h3>
                <div className="checkout-input">
                    <label htmlFor="buyerName">Imię i mazwisko</label>
                    <input type="text" className={errors.noBuyerName ? "error" : ""} onChange={handleFormChange}
                           id="buyerName"
                           name="buyerName"
                           placeholder="Jan Kowalski"/>
                </div>
                <div className="checkout-input">
                    <label htmlFor="email">Adres e-mail</label>
                    <input type="email" id="email" name="email"
                           className={errors.noEmail ? "error" : ""}
                           onChange={handleFormChange}
                           placeholder="np. jan.kowalski@corinnyliny.pl"/>
                </div>
                <div className="checkout-input">
                    <label htmlFor="phone">Numer telefonu</label>
                    <input type="tel" className={errors.noPhone ? "error" : ""} onChange={handleFormChange} id="phone"
                           name="phone"
                           placeholder="np. 123456789"/>
                </div>
                <div className={errors.noPolicy ? "checkout-privacy error" : "checkout-privacy"}>
                    <input type="checkbox" id="privacyPolicy"
                           onChange={handleFormChange}
                           name="privacyPolicy" required/>
                    <label htmlFor="privacyPolicy">Akceptuję <Link to="/prywatnosc">politykę
                        prywatności</Link> i zgadzam się na przetwarzanie moich danych przez sprzedającego w celu
                        realizacji zamówienia.</label>
                </div>
            </div>
            <DeliveryOptions callback={changeDeliveryOptions} baseDeliveryCost={BASE_DELIVERY_COST} />
            <CheckoutSummary items={items} totalPrice={totalPrice} deliveryCost={deliveryCost}/>
            <div className="checkout-button" onClick={() => checkout()}>Złóż zamówienie</div>
        </div>
    );
}

export default Checkout