import LightGallery from "lightgallery/react";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import cordeoImg from "../../assets/cordeo.jpg";

const CordeoDescription = () => {
    const onInit = () => {
    };

    return (
        <div>
            <div className="description box">
                <div className="description-content">
                    <h2>Cordeo</h2>
                    <p>
                        Cordeo standardowo produkujemy z liny o grubości 12 mm, na życzenie dostępna jest również wersja
                        z liny 14 mm. Podstawowa wersja składa się z liny połączonej metalowym kółkiem.
                    </p>
                </div>
                <div className="description-photos single">
                    <LightGallery onInit={onInit} speed={500} plugins={[lgThumbnail, lgZoom]}>
                        <a href={cordeoImg}>
                            <img className="product-img" src={cordeoImg} alt="Cordeo"/>
                        </a>
                    </LightGallery>
                </div>
            </div>
        </div>
    )
}

export default CordeoDescription;