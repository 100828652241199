// import styles
import './Picker.scss';

// import plugins if you need
import {firebaseStorage} from "../../firebase";
import {useState, useEffect} from 'react';
import {ref, listAll, getDownloadURL} from 'firebase/storage';
import ImageSkeleton from "../../ImageSkeleton/ImageSkeleton";

const Picker = ({name, handler, type}) => {
    const [images, setImages] = useState([]);
    const [pickedIndex, setPickedIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isPickerVisible, setPickerVisible] = useState(false);

    const toggleOverlay = () => {
        setPickerVisible(!isPickerVisible);
    };

    useEffect(() => {
        const fetchImages = async () => {
            let path;
            if (name && type) {
                path = type + "_" + name;
            } else {
                path = name;
            }

            const galleryRef = ref(firebaseStorage, path + "/"); // Reference to the 'gallery' folder
            let imageList = [];

            try {
                const response = await listAll(galleryRef);
                const downloadURLPromises = response.items.map(itemRef => getDownloadURL(itemRef));
                imageList = await Promise.all(downloadURLPromises);
                setImages(imageList);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching images: ", error);
                setLoading(false);
            }
        };

        fetchImages();
    });


    let title = "CorinnyLiny.pl #";
    if (name === "ropes") {
        title = "lina #"
    } else if (name === "halters") {
        title = "halter #"
    } else if (name === "leash") {
        title = "smycz #"
    }

    const handlePick = index => {
        setPickedIndex(index);
        handler(index);
    }

    return (
        <div className="picker-container">
            {isPickerVisible && (
                <div className="picker-overlay" onClick={toggleOverlay}>
                    <div className="picker-overlay-container">
                        {images.map((url, index) => (
                            <img className="picker-img"
                                 src={url}
                                 alt={`${title} ${index + 1}`}
                                 onClick={() => handlePick(index)}
                            />
                        ))}
                    </div>
                </div>
            )}
            {loading ? (
                <div className="lg-react-element">
                    {new Array(1).fill(0).map((_, index) => <ImageSkeleton key={index} isGrid={true}/>)}
                </div>
            ) : (
                <div className="image-picker" onClick={toggleOverlay}>
                    <img className="picker-picked" src={images[pickedIndex]} alt="Lina"/>
                    <div className="picker-button">
                        <p className="amount">wybierz inny kolor</p>
                        <p className="description">Dostępne są {images.length - 1} inne wzory</p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Picker;