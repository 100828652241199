import React, {useEffect, useState} from 'react';
import "./Basket.scss";
import BasketItem from "./BasketItem";
import {Link} from "react-router-dom";

const Basket = () => {
    // Directly initialize state with items from local storage
    const storedItems = localStorage.getItem('basket');
    const initialItems = storedItems ? JSON.parse(storedItems) : [];
    const [items, setItems] = useState(initialItems);
    const [totalPrice, setTotalPrice] = useState(0);

    const inpostCost = 20;

    const onRemove = itemIndex => {
        items.splice(itemIndex, 1);
        setItems([...items]);
        const updatedItems = [...items];
        localStorage.setItem('basket', JSON.stringify(updatedItems));
        window.dispatchEvent(new CustomEvent('basketUpdated', {detail: updatedItems}));
    }

    useEffect(() => {
        const sum = items.reduce((acc, item) => acc + parseFloat(item.price), 0);
        setTotalPrice(sum);
    }, [items]);

    const basketContent = (
        <div className="content basket">
            <div className="column-left">
                <h2>Twoje zamówienie</h2>
                {items.map((item, index) => (
                    <BasketItem
                        key={index}
                        itemId={index}
                        imgIndex={item.colorIndex}
                        title={item.productName}
                        price={item.price}
                        removeHandler={onRemove}
                    />
                ))}
            </div>
            <div className="column-right">
                <h2>Podsumowanie</h2>
                <div className="basket-summary-container">
                    <div className="basket-summary">
                        <h3>Wartość produktów:</h3>
                        <p className="total-price">{totalPrice}<span>,00</span> <span>zł</span></p>
                    </div>
                    <div className="basket-summary">
                        <h3>Koszt wysyłki:</h3>
                        <p className="total-price">{inpostCost}<span>,00</span> <span>zł</span></p>
                    </div>
                    <div className="basket-summary sum">
                        <h3>Razem z dostawą:</h3>
                        <p className="total-price-sum">{inpostCost + totalPrice}<span>,00</span> <span>zł</span></p>
                    </div>
                    <div className="basket-summary buttons">
                        <Link className="basket-button" to="/zamowienie">Zamów</Link>
                        <Link className="basket-button light" to="/konfigurator">Kontynuuj zakupy</Link>
                    </div>
                </div>
            </div>
        </div>
    );

    const noItemsInfo = (
        <div className="content basket">
            <div className="column-left">
                <h2>Twoje zamówienie</h2>
                <p>Nic tu jeszcze nie ma!</p>
                <p><Link to="/konfigurator">Zachęcam do obejrzenia mojej oferty!</Link></p>
            </div>
        </div>
    );

    return items.length > 0 ? basketContent : noItemsInfo;
};

export default Basket;