import LightGallery from "lightgallery/react";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import uwiazImg from "../../assets/uwiaz.jpg";

const UwiazDescription = () => {
    const onInit = () => {
    };

    return (
        <div>
            <div className="description box">
                <div className="description-content">
                    <h2>Uwiąz</h2>
                    <p>
                        Uwiąz tworzony jest z bardzo mocnej liny i grubości 14mm, do liny dołączony jest srebrny,
                        wytrzymały karabinek. Na życzenie możemy dostosować typ i kolor karabinka.
                    </p>
                </div>
                <div className="description-photos single">
                    <LightGallery onInit={onInit} speed={500} plugins={[lgThumbnail, lgZoom]}>
                        <a href={uwiazImg}>
                            <img className="product-img" src={uwiazImg} alt="Uwiąz"/>
                        </a>
                    </LightGallery>
                </div>
            </div>
        </div>
    )
}

export default UwiazDescription;