import {CARABINER, POPPER, ROPE} from "../Pricelist";

class RopeModel {
    constructor() {
        this.productName = "Lina do pracy z ziemi";
        this.thickness = 14; // default value
        this.length = 350; // default value
        this.colorIndex = 0; // default value, assuming it corresponds to an index in a color array
        this.carabiner = "none";
        this.popperType = "plain";
        this.popperDetails = "";
        this.price = this.calculatePrice();
    }

    setThickness(thickness) {
        this.thickness = thickness;
    }

    setLength(length) {
        this.length = length;
    }

    setColorIndex(colorIndex) {
        this.colorIndex = colorIndex;
    }

    setCarabiner(type) {
        this.carabiner = type;
    }

    setPopperType(type) {
        this.popperType = type;
    }

    getPopperType() {
        return this.popperType;
    }

    setPopperDetails(txt) {
        this.popperDetails = txt;
    }

    calculatePrice() {
        let price =
            ROPE.THICKNESS[this.thickness] + ROPE.LENGTH[this.length];

        if(this.carabiner === "custom") {
            price += CARABINER.CUSTOM;
        } else if(this.carabiner === "standard") {
            price += CARABINER.STANDARD;
        } else if (this.carabiner === "none") {
            price += CARABINER.NONE;
        }

        if(this.popperType === "individual") {
            price += POPPER.INDIVIDUAL;
        } else if (this.popperType === "graver") {
            price += POPPER.WITH_GRAVER;
        } else if (this.popperType === "plain") {
            price += POPPER.PLAIN;
        }

        this.price = price;
        return price;
    }
}

export default RopeModel;