import React from 'react';
import './Cennik.scss';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import {CARABINER, CORDEO, HALTER, POPPER, REIN, ROPE} from "../Sklep/Pricelist";

const PricelistHorses = () => {
    const generatePricelistTable = (data) => {
        const basePrices = data.THICKNESS;
        const lengthCosts = data.LENGTH;

        const headers = ["Długość:", ...Object.keys(lengthCosts).map(length => `${length / 100}m`)];

        const calculatePrices = (basePrice) => {
            return Object.values(lengthCosts).map(cost => basePrice + cost + ",00zł");
        };

        const tableBody = Object.entries(basePrices).map(([thickness, basePrice]) => (
            <tr key={thickness}>
                <td>Grubość {thickness}mm</td>
                {calculatePrices(basePrice).map((price, index) => (
                    <td key={index}>{price}</td>
                ))}
            </tr>
        ));

        return (
            <table className="content-table">
                <thead>
                <tr>
                    {headers.map((header, index) => (
                        <th key={index}>{header}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {tableBody}
                </tbody>
            </table>
        );
    };


    return (
        <div className="content pricelist">
            <Helmet>
                <title>Haltery, wodze linowe, cordeo, liny, uwiązy</title>
                <meta name="description"
                      content="Cennik produktów tj. halter, wodze linowe, cordeo, liny, uwiązy, ręcznie robione grawery"/>
            </Helmet>
            <h1>Cennik sprzętu jeździckiego</h1>
            <section className="content-section center">
                <h2>Każdy produkt mogę dopasować do indywidualnych potrzeb i wymagań!</h2>
                <p>Listę produktów z podstawowej oferty znajdziesz poniżej.</p>
                <p>Dodatkowo na zamówienie wykonuję również:<br/>
                    podwójne lonże <span>⊛</span> wodze mecate <span>⊛</span> westernowe
                    wodze dzielone <span>⊛</span> plecione napierśniki</p>
                <p>Ceny tych produktów ustalane są indywidualnie.</p>
                <p>Zachęcam do kontaktu i zadawania pytań!</p>
            </section>
            <hr/>
            <section className="content-section palety">
                <p>Przy każdym produkcie istnieje możliwość wyboru koloru liny:</p>
                <Link to="/paleta/12i14mm">grubość 12mm i 14mm</Link>
                <Link to="/paleta/8mm">grubość 8mm</Link>
            </section>
            <hr/>
            <section className="content-section">
                <h1>Liny do pracy z ziemi</h1>
                {generatePricelistTable(ROPE)}
                <ul>
                    <li>Lina zakończona prostym srebrnym karabinkiem: +{CARABINER.STANDARD},00zł</li>
                    <li>Zamiana poppera skórzanego na syntetyczny Bio Thane: w cenie</li>
                    <li>Inne kolory/typy karabinków: +{CARABINER.CUSTOM},00zł</li>
                    <li>Prosty grawer na popperze: +{POPPER.WITH_GRAVER},00zł</li>
                    <li>Artystyczne wykończenie poppera: +{POPPER.INDIVIDUAL},00zł</li>
                </ul>
            </section>

            <hr/>
            <section className="content-section">
                <h1>Wodze linowe</h1>
                {generatePricelistTable(REIN)}
                <ul>
                    <li>Standardowo zakończone srebrnymi karabińczykami szczypcowymi.</li>
                    <li>Inne typy/kolory karabińczyków do wyboru: +{CARABINER.STANDARD},00zł</li>
                    <li>Popper skórzany lub Bio Thane na środku wodzy: +{POPPER.PLAIN},00zł</li>
                    <li>Popper z grawerem: +{POPPER.WITH_GRAVER},00zł</li>
                    <li>Popper zdobiony artystycznie: +{POPPER.INDIVIDUAL},00zł</li>
                </ul>
            </section>
            <hr/>
            <section className="content-section">
                <h1>Cordeo</h1>
                <table className="content-table">
                    <thead>
                    <tr>
                        <th></th>
                        <th>Cena</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Grubość 12mm</td>
                        <td>{CORDEO.THICKNESS["12"]},00zł</td>
                    </tr>
                    <tr>
                        <td>Grobość 14mm</td>
                        <td>{CORDEO.THICKNESS["14"]},00zł</td>
                    </tr>
                    </tbody>
                </table>
                <ul>
                    <li>Cordeo z dwupunktową regulacją długości: +{CORDEO.REGULATION},00zł</li>
                    <li>Wykończenie cordeo do wyboru:
                        <ol>
                            <li>frędzel</li>
                            <li>łącznik z kółka</li>
                            <li>cordeo zamknięte, z niewidocznym miejscem łączenia liny!</li>
                        </ol>
                    </li>
                </ul>
            </section>
            <hr/>
            <section className="content-section">
                <h1>Uwiązy</h1>
                <ul>
                    <li>Długość 2,5m z prostym, srebrnym karabinkiem: 60zł</li>
                    <li>Inne kolory/typy karabinów - cena ustalana indywidualnie</li>
                </ul>
            </section>
            <hr/>
            <section className="content-section">
                <h1>Haltery</h1>
                <ul>
                    <li>Bez oplotu: {HALTER.THICKNESS["8"]},00zł (grubość do wyboru: 8mm lub 6mm)</li>
                    <li>Oplot na nachrapniku +{HALTER.CUSTOM_NOSE_BAND},00zł</li>
                </ul>
            </section>
            <hr/>
            <section className="content-section">
                <h1>Koszty dostawy</h1>
                <ul>
                    <li>Dostawa kurierem InPost lub do paczkomatu: 20zł</li>
                    <li>Możliwy odbiór osobisty na terenie Poznania</li>
                </ul>
            </section>
        </div>
    );
}

export default PricelistHorses;