import {HALTER} from "../Pricelist";

class HalterModel {
    constructor(cloneFrom = null) {
        if (cloneFrom instanceof HalterModel) {
            // Clone properties from the provided instance
            this.productName = cloneFrom.productName;
            this.thickness = cloneFrom.thickness;
            this.colorIndex = cloneFrom.colorIndex;
            this.braidOnTheNoseband = cloneFrom.braidOnTheNoseband;
            this.blueSize = cloneFrom.blueSize;
            this.redSize = cloneFrom.redSize;
            this.greenSize = cloneFrom.greenSize;
            this.yellowSize = cloneFrom.yellowSize;
            this.price = this.calculatePrice();
        } else {
            // Standard constructor logic with default values
            this.productName = "Halter";
            this.thickness = 8;
            this.colorIndex = 0;
            this.braidOnTheNoseband = false;
            this.blueSize = "";
            this.redSize = "";
            this.greenSize = "";
            this.price = this.calculatePrice(); // Assuming calculatePrice() doesn't depend on external state
        }
    }

    getBlueSize() {
        return this.blueSize;
    }

    setBlueSize(size) {
        this.blueSize = size;
    }

    setGreenSize(size) {
        this.greenSize = size;
    }

    setYellowSize(size) {
        this.yellowSize = size;
    }

    getGreenSize() {
        return this.greenSize;
    }

    getYellowSize() {
        return this.yellowSize;
    }

    setRedSize(size) {
        this.redSize = size;
    }

    getRedSize() {
        return this.redSize;
    }

    setThickness(thickness) {
        this.thickness = thickness;
    }

    setColorIndex(colorIndex) {
        this.colorIndex = colorIndex;
    }

    toggleBraidOnTheNoseband() {
        this.braidOnTheNoseband = !this.braidOnTheNoseband;
    }

    calculatePrice() {
        let price =
            HALTER.THICKNESS[this.thickness]

        if (this.braidOnTheNoseband) {
            price += HALTER.CUSTOM_NOSE_BAND
        }

        this.price = price;
        return price;
    }
}

export default HalterModel;