import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import "./Menu.scss";
import logo from "../../assets/logo-inverted.png";

const Menu = () => {

    const [basketItemCount, setBasketItemCount] = useState(0);
    const [mobileMenuVisibility, setMobileMenuVisibility] = useState(false);
    const [alpha, setAlpha] = useState(0);


    const updateBasketItemCount = () => {
        const basket = JSON.parse(localStorage.getItem('basket')) || [];
        setBasketItemCount(basket.length);
    };

    const location = useLocation();
    const isActive = (path) => {
        return location.pathname === path;
    }

    useEffect(() => {
        if (window.pageYOffset > 0) {
            window.scrollTo({
                top: 0
            });
        }

        updateBasketItemCount();
        if (window.location.pathname === "/") {
            setAlpha(0);
            const handleScroll = () => {
                const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
                const newAlpha = Math.min(0.9, scrollPosition / 1000);
                setAlpha(newAlpha);
            };

            const handleBasketUpdate = () => updateBasketItemCount();
            window.addEventListener('basketUpdated', handleBasketUpdate);
            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('basketUpdated', handleBasketUpdate);
                window.removeEventListener('scroll', handleScroll);
            }
        } else {
            const handleBasketUpdate = () => updateBasketItemCount();
            window.addEventListener('basketUpdated', handleBasketUpdate);
            setAlpha(0.8);

            return () => {
                window.removeEventListener('basketUpdated', handleBasketUpdate);
            }
        }
    }, [location.pathname]);



    const toggleMenuVisibility = (isVisible) => {
        setMobileMenuVisibility(isVisible);
    }

    return (
        <div className="menu-container"
             style={{background: `rgba(0, 0, 0, ${alpha})`}}>
            {mobileMenuVisibility ? <div className="mobile-menu-background" onClick={() => {
                toggleMenuVisibility(!mobileMenuVisibility)
            }}/> : ""}
            <Link style={{opacity: alpha * 2}} className="menu_logo" to="/">
                <img src={logo} alt="Ręcznie robiony sprzęt dla koni i psów - Corinny Liny" />
            </Link>
            <nav className="menu main_page">
                <div className={mobileMenuVisibility ? "mobile-menu-button active" : "mobile-menu-button"}
                     onClick={() => {
                         toggleMenuVisibility(!mobileMenuVisibility)
                     }}>
                    <p>menu</p>
                </div>
                <div className={mobileMenuVisibility ? "menu-content mobile-visible" : "menu-content"}>
                    <Link
                        onClick={() => {
                            toggleMenuVisibility(false)
                        }}
                        to="/dlakonia" className={isActive('/dlakonia') ? 'active' : ''}>Dla konia</Link>

                    <Link
                        onClick={() => {
                            toggleMenuVisibility(false)
                        }}
                        to="/dlapsa" className={isActive('/dlapsa') ? 'active' : ''}>Dla psa</Link>

                    <Link
                        onClick={() => {
                            toggleMenuVisibility(false)
                        }}
                        to="/galeria" className={isActive('/galeria') ? 'active' : ''}>Galeria</Link>

                    <Link
                        onClick={() => {
                            toggleMenuVisibility(false)
                        }}
                        to="/kontakt" className={isActive('/kontakt') ? 'active' : ''}>Kontakt</Link>

                    <Link to="/koszyk" onClick={() => {
                        toggleMenuVisibility(false)
                    }} className={isActive('/koszyk') ? 'active' : ''}>Zamówienia <sup
                        className={`basket-count ${basketItemCount === 0 ? "disabled" : ""}`}>{basketItemCount}</sup></Link>

                </div>
            </nav>
        </div>
    );

}

export default Menu;