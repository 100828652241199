import "../Products.scss"
import ReinsDescription from "./ReinsDescription";
import Picker from "../../Galeria/Picker/Picker";
import {useState} from "react";
import ReinModel from "./ReinModel";
import AddToBasket from "../basket/AddToBasket";
import {Helmet} from "react-helmet";

const Reins = () => {

    const [rein] = useState(new ReinModel());
    const [price, setPrice] = useState(rein.calculatePrice());

    const handleThicknessChange = (e) => {
        rein.setThickness(e.target.value)
        if (price !== rein.calculatePrice()) {
            setPrice(rein.calculatePrice());
        }
    };

    const handleLengthChange = (e) => {
        rein.setLength(e.target.value)
        setPrice(rein.calculatePrice());
    };

    const handleRopeColorChange = (index) => {
        rein.setColorIndex(index);
    }

    const handlePopperChange = e => {
        const value = e.target.value;
        rein.setPopperType(value);
        setPrice(rein.calculatePrice());
    }

    const handlePopperDetailsInput = e => {
        const value = e.target.value;
        rein.setPopperDetails(value);
    }

    const handleCarabinerChange = e => {
        const v = e.target.value;
        rein.setCarabiner(v);

        setPrice(rein.calculatePrice());
    }

    return (
        <div className="content product">
            <Helmet>
                <title>Wodza linowa dla konia, ręcznie robiona - Corinny Liny</title>
                <meta name="description"
                      content="Ręcznie wytwarzana wodza linowa jeździecka na indywidualne zamówienie, jeździectwo"/>
                <link rel="canonical" href="https://corinnyliny.pl/dlakonia/wodze"/>
            </Helmet>
            <div className="column column-left">
                <ReinsDescription/>
                <div className="thickness box">
                    <p>Wybierz grubość:</p>
                    <div className="select-picker mobile-only">
                        <select id="thicknessSelect" onChange={handleThicknessChange}>
                            <option value="14" defaultChecked="true">14mm</option>
                            <option value="12" onChange={handleThicknessChange}>12mm</option>
                        </select>
                    </div>
                    <div className="radio-picker">
                        <label>
                            <input type="radio" id="14mm" value="14" name="thickness" defaultChecked="true"
                                   onChange={handleThicknessChange}/>
                            <span>14mm</span>
                        </label>

                        <label>
                            <input type="radio" id="12mm" value="12" name="thickness" onChange={handleThicknessChange}/>
                            <span>12mm</span>
                        </label>
                    </div>
                </div>
                <div className="rope-length box">
                    <p>Wybierz długość:</p>
                    <div className="select-picker mobile-only">
                        <select id="ropeLengthSelect" onChange={handleLengthChange}>
                            <option value="280">2,8m</option>
                            <option value="350">3,5m</option>
                        </select>
                    </div>
                    <div className="radio-picker">
                        <label>
                            <input type="radio" id="280mm" value="280" onChange={handleLengthChange}
                                   defaultChecked="true"
                                   name="rope-length"/>
                            <span>2,8mm</span>
                        </label>

                        <label>
                            <input type="radio" id="350mm" value="350" name="rope-length"
                                   onChange={handleLengthChange}/>
                            <span>3,5mm</span>
                        </label>
                    </div>
                </div>

                <div className="rope-addons box">
                    <p className="options-title">Wybierz elementy opcjonalne</p>
                    <p className="radio-picker-title">Karabinek:</p>
                    <div className="options-picker">
                        <label>
                            <input type="radio" id="carabiner" onChange={handleCarabinerChange} value="standard"
                                   defaultChecked="true"
                                   name="addon"/>
                            <span>standardowy</span>
                        </label>
                        <label>
                            <input type="radio" id="carabiner" onChange={handleCarabinerChange} value="custom"
                                   name="addon"/>
                            <span>dobrany indywidualnie</span>
                        </label>
                    </div>

                    <p className="radio-picker-title">Skórzany popper:</p>
                    <div className="options-picker">
                        <label>
                            <input type="radio" id="popper" value="none" onChange={handlePopperChange}
                                   defaultChecked="true"
                                   name="addon-popper"/>
                            <span>brak</span>
                        </label>

                        <label>
                            <input type="radio" id="popper" value="plain" onChange={handlePopperChange}
                                   name="addon-popper"/>
                            <span>bez zdobień</span>
                        </label>

                        <label>
                            <input type="radio" id="popper" onChange={handlePopperChange} value="graver"
                                   name="addon-popper"/>
                            <span>grawer</span>
                        </label>

                        <label>
                            <input type="radio" id="popper" onChange={handlePopperChange} value="individual"
                                   name="addon-popper"/>
                            <span>artystyczny</span>
                        </label>
                    </div>
                </div>
                <div className={`popper-detail ${rein.getPopperType() === "graver" ? "visible" : "invisible"}`}>
                    <p className="popper-detail-desc">Podaj treść grawera:</p>
                    <input id="popper-graver-text"
                           onChange={handlePopperDetailsInput}
                           type="text" className="graver-input" placeholder="Twój napis..." maxLength={20} />
                </div>
                <div className={`popper-detail ${rein.getPopperType() === "individual" ? "visible" : "invisible"}`}>
                    <p className="popper-detail-desc">Opisz swój pomysł/wizję:</p>
                    <textarea id="popper-individual-textarea"
                              onChange={handlePopperDetailsInput}
                              placeholder="Opisz w paru słowach co chciał(a)byś
                    umieścić na popperze. Może być to np. napis, wzór lub to i to. Może być to też jakaś inspiracja lub pomysł, który rozwinę dla Ciebie."/>
                </div>
            </div>
            <div className="column column-right">
                <div className="rope-color">
                    <Picker name="ropes" handler={handleRopeColorChange}/>
                </div>
                <AddToBasket item={rein}/>
            </div>
        </div>
    );

}

export default Reins;