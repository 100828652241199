import ReactGA from 'react-ga4';

const TRACKING_ID = "G-6GF9Y4W2NF"; // Your Google Analytics 4 Measurement ID

export const analyticsInitialise = () => {
    ReactGA.initialize(TRACKING_ID, {
        gtagUrl: "https://www.googletagmanager.com/gtag/js?id=G-6GF9Y4W2NF"
    });
}

export const trackPageView = (pathname) => {
    ReactGA.send({hitType: "pageview", page: pathname});
};

export const trackPurchaseConversion = (itemName, price) => {
    ReactGA.event({
        category: "equestrian",
        action: "purchase",
        label: "Purchased: " + itemName,
        value: price,
    });
};

export const trackBasketAdd = (itemName, price) => {
    ReactGA.event({
        category: "equestrian",
        action: "add_to_cart",
        label: itemName,
        value: price,
    });
}

export const trackClick = (label, value) => {
    let trackingValue = label;
    if (value) {
        trackingValue += ": " + value;
    }

    ReactGA.event({
        category: "general",
        action: "click",
        label: trackingValue
    });
}

