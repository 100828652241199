import {trackBasketAdd, trackClick} from "../../Utility/Analytics";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import BasketPopup from "./BasketPopup";
import "./BasketPopup.scss"

const AddToBasket = ({item}) => {

    const [isAdded, setIsAdded] = useState(false);

    useEffect(() => {
        if (isAdded) {
            // Disable scroll
            document.body.style.overflow = 'hidden';
        } else {
            // Enable scroll
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isAdded]);

    const onClick = () => {
        let basket = JSON.parse(localStorage.getItem('basket')) || [];
        basket.push(item);
        localStorage.setItem('basket', JSON.stringify(basket));
        window.dispatchEvent(new CustomEvent('basketUpdated'));
        setIsAdded(true);
        trackBasketAdd(item.productName, item.price);
    }

    const close = () => {
        setIsAdded(false);
        trackClick("Cart popup close");
    }

    const whenAdded = (
        <div className="go-to-basket" onClick={close}>
            <div className="basket-popup">
                <h2>Produkt został zapisany</h2>
                <BasketPopup/>
                <div className="popup-buttons">
                    <span className="popup-close" onClick={close}>Przeglądam dalej</span>
                    <Link className="popup-ok" to="/koszyk">Chcę zamówić!</Link>
                </div>
            </div>
        </div>
    );

    return (
        <div className="add-to-cart-container">
            <div className="rope-price">
                <p className="price-name">Cena: <span>{item.price} zł</span></p>
                <p onClick={onClick} className="add-to-cart">
                    <span className="cart-img"/>
                    <span>Zapisz na potem</span>
                </p>
                {isAdded ? whenAdded : ""}
            </div>
            {/*<div className="or"><div className="line"/><span>albo</span></div>*/}
            {/*<Link className="ask-button" to="/zapytaj">zapytaj o przedmiot!</Link>*/}
        </div>
    );
}

export default AddToBasket;