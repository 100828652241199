import {useLocation, useNavigate} from "react-router-dom";
import "./ItemDetails.scss"
import STATUSES from "./STASUSES";
import OrderBasket from "./OrderBasket";
import {firebaseFirestore} from "../firebase";
import {doc, deleteDoc} from 'firebase/firestore';

const ItemPreview = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const order = location.state.order;

    const handlePrint = () => {
        const printContents = document.getElementById("printable-content").innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;

        // Remove the element with the specific ID before printing
        const elementToRemove = document.getElementById("non-printable");
        if (elementToRemove) {
            elementToRemove.parentNode.removeChild(elementToRemove);
        }

        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload(); // Reload to restore original content
    };

    const handleDelete = async () => {
        try {
            await deleteDoc(doc(firebaseFirestore, 'orders', order.id));
            navigate("/panel"); // Navigate back to the admin panel after deletion
        } catch (error) {
            console.error("Error deleting order: ", error);
        }
    };

    return (
        <>
            <div className="content item-details">
                <h2>Zamówienie nr {order.orderId}</h2>
                <h3>Data zamówienia: {order.date}</h3>
                {order ? (
                    <>
                        <div className="item-price-info">
                            <p>
                                <strong>Produkty:</strong> {order.totalPrice - order.deliveryDetails?.deliveryCost},<small>00</small> zł
                            </p>
                            <p><strong>Wysyłka:</strong> {order.deliveryDetails?.deliveryCost},<small>00</small> zł</p>
                            <p><strong>Razem:</strong> {order.totalPrice},<small>00</small> zł</p>
                            <p><strong>Status:</strong> {STATUSES[order.status]}</p>
                        </div>
                        <div className="item-delivery-info">
                            <h4>Informacje o dostawie</h4>
                            <table>
                                <tbody>
                                <tr>
                                    <th>Imię i nazwisko</th>
                                    <td>{order.buyerName}</td>
                                </tr>
                                <tr>
                                    <th>E-mail</th>
                                    <td>{order.email}</td>
                                </tr>
                                <tr>
                                    <th>Telefon</th>
                                    <td>{order.phone}</td>
                                </tr>
                                <tr>
                                    <th>Sposób wysyłki</th>
                                    <td>{order.deliveryDetails?.deliveryType ? order.deliveryDetails.deliveryType : "nie podano"}</td>
                                </tr>
                                <tr>
                                    <th>Adres wysyłki</th>
                                    <td>{order.deliveryDetails?.deliveryAddress ? order.deliveryDetails.deliveryAddress : "nie podano"}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <h4>Zamówione produkty</h4>
                        <OrderBasket basket={order.basket.items}/>
                    </>
                ) : (
                    <p>Brak danych</p>
                )}
            </div>
            <div className="admin-advaced-options" id="non-printable">
                <button onClick={handlePrint}>Drukuj</button>
                {order.status === "closed" && <button onClick={handleDelete}>Usuń zamówienie</button>}
            </div>

        </>
    );
}

export default ItemPreview;