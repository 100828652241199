import "./Intro.scss";
import {Link} from "react-router-dom";

const HorsesIntro = () => {
    return (
        <div className="poster products-animals">
            <Link className="box wodze"  to={"/dlakonia/wodze/"}><p>Wodze linowe</p></Link>
            <Link className="box halter" to={"/dlakonia/haltery/"}><p>Haltery</p></Link>
            <Link className="box liny" to={"/dlakonia/liny/"}><p>Liny do pracy ziemi</p></Link>
            <Link className="box cordeo more" to={"/dlakonia/cordeo/"}><p>Cordeo</p></Link>
        </div>
    );

}

export default HorsesIntro;