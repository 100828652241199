import "../Products.scss"
import Picker from "../../Galeria/Picker/Picker";
import {useState} from "react";
import CordeoModel from "./CordeoModel";
import CordeoDescription from "./CordeoDescription";
import AddToBasket from "../basket/AddToBasket";
import {Helmet} from "react-helmet";

const Cordeo = () => {

    const [cordeo] = useState(new CordeoModel());
    const [price, setPrice] = useState(cordeo.calculatePrice());

    const handleThicknessChange = (e) => {
        cordeo.setThickness(e.target.value)
        if (price !== cordeo.calculatePrice()) {
            setPrice(cordeo.calculatePrice());
        }
    };

    const handleCordeoColorChange = (index) => {
        cordeo.setThickness(index);
    }

    const toggleTwoPointRegulation = () => {
        cordeo.toggleTwoPointRegulation()
        setPrice(cordeo.calculatePrice());
    }

    const handleAddonChange = e => {
        const value = e.target.value;
        cordeo.setFinish(value);
    }

    return (
        <div className="content product">
            <Helmet>
                <title>Cordeo na indywidualne zamówinie, artystyczne - Corinny Liny</title>
                <meta name="description" content="Cordeo dla konia, dużo wzorów i kolorów wysokiej jakości lin" />
                <link rel="canonical" href="https://corinnyliny.pl/dlakonia/cordeo" />
            </Helmet>
            <div className="column column-left">
                <CordeoDescription/>
                <div className="thickness box">
                    <p>Wybierz grubość:</p>
                    <div className="select-picker mobile-only">
                        <select id="thicknessSelect" onChange={handleThicknessChange}>
                            <option value="14" defaultChecked="true">14mm</option>
                            <option value="12" onChange={handleThicknessChange}>12mm</option>
                        </select>
                    </div>
                    <div className="radio-picker">
                        <label>
                            <input type="radio" id="14mm" value="14" name="thickness" defaultChecked="true"
                                   onChange={handleThicknessChange}/>
                            <span>14mm</span>
                        </label>

                        <label>
                            <input type="radio" id="12mm" value="12" name="thickness" onChange={handleThicknessChange}/>
                            <span> 12mm</span>
                        </label>
                    </div>
                </div>
                <div className="rope-addons box">
                    <p>Element opcjonalny :</p>
                    <label>
                        <input type="checkbox" id="twoPointRegulation" onChange={toggleTwoPointRegulation}
                               value="twoPointRegulation"/>
                        <span>Dwupunktowa regulacja długości</span>
                    </label>
                </div>
                <div className="rope-addons box">
                    <p>Rodzaj wykończenia:</p>
                    <label>
                        <input type="radio" id="popper" value="carabiner-type" onChange={handleAddonChange}
                               name="addonType"/>
                        <span>Frędzel</span>
                    </label>

                    <label>
                        <input type="radio" id="graver" onChange={handleAddonChange} value="grawer" name="addonType"/>
                        <span>Łącznik z kółka</span>
                    </label>

                    <label>
                        <input type="radio" id="art-popper" onChange={handleAddonChange} value="art-popper"
                               name="addonType"/>
                        <span>Niewidoczne miejsce łączenia liny</span>
                    </label>
                </div>
            </div>
            <div className="column column-right">
                <div className="rope-color">
                    <Picker name="ropes" handler={handleCordeoColorChange}/>
                </div>
                <AddToBasket item={cordeo}/>
            </div>
        </div>
    )
        ;

}

export default Cordeo;