import {CORDEO} from "../Pricelist";

class CordeoModel {
    constructor() {
        this.productName = "Cordeo";
        this.thickness = 14; // default value
        this.colorIndex = 0; // default value, assuming it corresponds to an index in a color array
        this.twoPointRegulation = false;
        this.finish = "frędzel";
        this.price = this.calculatePrice();
    }

    setThickness(thickness) {
        this.thickness = thickness;
    }

    setColorIndex(index) {
        this.colorIndex = index;
    }

    toggleTwoPointRegulation() {
        this.twoPointRegulation = !this.twoPointRegulation;
    }

    setFinish(name) {
        this.finish = name;
    }

    calculatePrice() {
        let price = CORDEO.THICKNESS[this.thickness];

        if (this.twoPointRegulation) {
            price += CORDEO.REGULATION
        }

        this.price = price;
        return price;
    }
}

export default CordeoModel;