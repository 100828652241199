import LightGallery from 'lightgallery/react';

// import styles
import './Galeria.css';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import {firebaseStorage} from "../firebase";
import {useState, useEffect} from 'react';
import {ref, listAll, getDownloadURL} from 'firebase/storage';
import ImageSkeleton from "../ImageSkeleton/ImageSkeleton";
import {Helmet} from "react-helmet";

const Galeria = (props) => {
    const onInit = () => {
    };

    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchImages = async () => {
            const galleryRef = ref(firebaseStorage, props.name + "/"); // Reference to the 'gallery' folder
            let imageList = [];

            try {
                const response = await listAll(galleryRef);
                const downloadURLPromises = response.items.map(itemRef => getDownloadURL(itemRef));
                imageList = await Promise.all(downloadURLPromises);
                setImages(imageList);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching images: ", error);
                setLoading(false);
            }
        };

        fetchImages();
    }, [props.name]);


    let title = "CorinnyLiny.pl #";
    if (props.name === "ropes") {
        title = "lina #"
    } else if (props.name === "halters") {
        title = "halter #"
    }

    return (
        <div className="content">
            <Helmet>
                <title>Galeria: liny, cordeo, haltery, wodze linowe - Corinny Liny</title>
                <meta name="description" content="Zdjęcia produktów wykonanych na indywidualne zamówienie dla jeźdźców i trenerów koni.
                Przedstawiony ręcznie robiony sprzęt dla koni taki jak haltery, wodze linowe czy liny do pracy z ziemi." />
            </Helmet>
            <div className="gallery-container">
                {loading ? (
                    <div className="lg-react-element">
                        {new Array(20).fill(0).map((_, index) => <ImageSkeleton key={index} isGrid={true}/>)}
                    </div>
                ) : (
                    <LightGallery onInit={onInit} speed={500} plugins={[lgThumbnail, lgZoom]}>
                        {images.map((url, index) => (
                            <a key={index} href={url}>
                                <img className="gallery-img" src={url}
                                     alt={`${title} ${index + 1}`}/> {/* Used template literals */}
                                {props.name !== "gallery" && <span>{`${title}${index + 1}`}</span>}
                            </a>
                        ))}
                    </LightGallery>
                )}
            </div>
        </div>
    );
}

export default Galeria;