const SETUP = {
    static: {
        basePrice: 120,
        productName: "Smycz statyczna",
        minLength: 300,
        description: "Wykonana z liny statycznej, która świetnie sprawdza się jako smycz. Jest miła w dotyku, lekka, o ciasnym splocie, która ogranicza wnikanie piasku i brudu. No i spójrz na te kolory!"
    },
    dynamic: {
        basePrice: 140,
        productName: "Smycz dynamiczna",
        minLength: 300,
        description: "Jest praktycznie nie do zdarcia! Nieco sztywniejsza niż smycz z liny statycznej. Dzięki dynamicznym (rozciągliwym przy szarpnieciach) jest doskonałą smyczą dla psów większych ras oraz tych, które lubią ciągnąć na smyczy, ponieważ lina pochłania częściowo energię szarpnięcia."
    },
    ring: {
        basePrice: 90,
        productName: "Smycz ringówka",
        description: "Estetyczna smycz i obroża w jednym. Przeznaczona jest na wystawy. Pozwali doskonale zaprezentować urodę Twojego pupila."
    },
    customCarabiner: 30,
    pricePerMeter: 20,
    dogName: 30,
    graver: 30
}

class LeashModel {
    constructor(typeString) {
        if (typeString === "static") {
            this.type = SETUP.static;
        } else if (typeString === "dynamic") {
            this.type = SETUP.dynamic;
        } else if (typeString === "ring") {
            this.type = SETUP.ring;
        } else {
            this.type = SETUP.static;
        }

        this.productName = this.type.productName;
        this.colorIndex = 0; // default value, assuming it corresponds to an index in a color array
        this.length = this.type.minLength // default value, assuming it corresponds to an index in a color array
        this.customCarabiner = false;
        this.hasGraver = false;
        this.dogName = false;
    }

    toggleGraver() {
        this.hasGraver = !this.hasGraver;
    }

    toggleDogName() {
        this.dogName = !this.dogName;
    }

    setColorIndex(index) {
        this.colorIndex = index;
    }

    toggleCarabiner() {
        this.customCarabiner = !this.customCarabiner;
    }

    setLength(length) {
        this.length = length;
    }

    calculatePrice() {
        let price = this.type.basePrice;

        price += (this.length - this.type.minLength) * SETUP.pricePerMeter / 100;

        if (this.customCarabiner) {
            price += SETUP.customCarabiner;
        }

        if (this.hasGraver) {
            price += SETUP.graver;
        }

        if (this.dogName) {
            price += SETUP.dogName;
        }

        this.price = price;

        return price;
    }
}

export default LeashModel;