import "./BasketPopup.scss";
import {useEffect, useState} from "react";
import {getDownloadURL, ref} from "firebase/storage";
import {firebaseStorage} from "../../firebase";

const BasketItemPopup = ({itemId, imgIndex, title, price}) => {

    const [image, setImage] = useState(null);

    const getImageFilename = () => {
        let filename;
        let index = "0";
        if (title === "Halter") {
            filename = "halters";
            if (imgIndex < 10) {
                index += "000"
            } else if (imgIndex < 100) {
                index += "00";
            }

            index += imgIndex;
            filename += "/corinnyliny" + index + ".jpeg";
        } else if (title === "Smycz dynamiczna") {
            if (imgIndex < 10) {
                index += "0"
            }

            index += imgIndex;
            filename = "dynamic_leashes/" + index + ".jpg"
        } else if (title === "Smycz statyczna") {
            if (imgIndex < 10) {
                index += "0"
            }

            index += imgIndex;
            filename = "static_leashes/" + index + ".jpg"
        } else {
            filename = "ropes";
            if (imgIndex < 10) {
                index += "000"
            } else if (imgIndex < 100) {
                index += "00";
            }

            index += imgIndex;
            filename += "/corinnyliny" + index + ".jpeg";
        }

        return filename;
    }

    useEffect(() => {
        const fetchImages = async () => {
            // Array of filenames you want to download
            const filenames = [getImageFilename()];

            try {
                // Map through filenames to create references and get download URLs
                const downloadURLPromises = filenames.map(filename => {
                    const fileRef = ref(firebaseStorage, `${filename}`);
                    return getDownloadURL(fileRef);
                });

                const imageList = await Promise.all(downloadURLPromises);
                setImage(imageList[0]);
            } catch (error) {
                console.error("Error fetching images: ", error);
            }
        };
            fetchImages();
    });

    return (
        <div className="basket-item-popup">
            <div className="item-image">
                <img src={image} alt="Podgląd"/>
            </div>
            <div className="item-description">
                <p className="product-name">{title}</p>
            </div>
            <div className="item-price">
                <p>{price}<span>,00</span><span>zł</span></p>
            </div>
        </div>
    );
};

export default BasketItemPopup;