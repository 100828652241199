import {useEffect, useState} from "react";
import {collection, getDocs, orderBy, query, doc, updateDoc} from 'firebase/firestore';
import {firebaseFirestore} from "../firebase";
import "./Admin.scss";
import {Link} from "react-router-dom";
import STATUSES from "./STASUSES";

const Admin = () => {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const ordersCollection = collection(firebaseFirestore, 'orders');
                const ordersQuery = query(ordersCollection, orderBy('orderId', 'desc'));
                const ordersSnapshot = await getDocs(ordersQuery);
                const ordersList = ordersSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setOrders(ordersList);
            } catch (error) {
                console.error("Error fetching orders: ", error);
            }
        };

        fetchOrders();
    }, []);

    const handleStatusChange = async (orderId, newStatus) => {
        try {
            const orderDoc = doc(firebaseFirestore, 'orders', orderId);
            await updateDoc(orderDoc, { status: newStatus });
            setOrders(prevOrders => prevOrders.map(order =>
                order.id === orderId ? { ...order, status: newStatus } : order
            ));
        } catch (error) {
            console.error("Error updating status: ", error);
        }
    };

    return (
        <div className="content admin">
            <h1>Zamówienia</h1>
            {orders.map((order) => (
                <div className={`order-item ${order.status}`} key={order.id}>
                    <div className="item-left">
                        <p className="item-id"><strong>Nr zamówienia:</strong> {order.id}</p>
                        <p className="item-info"><strong>Od:</strong> {order.buyerName}</p>
                        <p className="item-info"><strong>Data:</strong> {order.date}</p>
                        <p className="item-info"><strong>Wartość
                            zamówienia:</strong> {order.totalPrice},<small>00</small> zł</p>
                    </div>
                    <div className="item-right">
                        <p className="item-status">
                            <strong>Status</strong>
                            <select
                                className="status-select"
                                value={order.status}
                                onChange={(e) => handleStatusChange(order.id, e.target.value)}
                            >
                                {Object.keys(STATUSES).map((statusKey) => (
                                    <option key={statusKey} value={statusKey}>
                                        {STATUSES[statusKey]}
                                    </option>
                                ))}
                            </select>
                        </p>
                        <Link
                            className="item-more"
                            to={`/panel/details`}
                            state={{order}}
                        >
                            Zobacz
                        </Link>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Admin;