import './Main.css';
import './Intro.scss';
import {Helmet} from "react-helmet";
import HorsesIntro from "./HorsesIntro";
import DogsIntro from "./DogsIntro";

const Main = () => {

    return (
        <div className="main-page-content">
            <Helmet>
                <title>Liny i sprzęt dla jeźdzców i koni na indywidualne zamówienie - Corinny Liny</title>
                <meta name="description"
                      content="Liny, wodze linowe, cordeo, haltery dla koni - wysokiej jakości i artystycznie wykończone"/>
            </Helmet>
            <div className="poster-container">
                <h1>ręcznie tworzony sprzęt</h1>
                <h2>dla koni, psów i ich opiekunów</h2>
            </div>
            <div className="for-animal-container horses">
                <h1 className="category-header">Dla konia</h1>
                <HorsesIntro/>
            </div>
            <div className="for-animal-container dogs">
                <h1 className="category-header dog">Dla psa</h1>
                <DogsIntro/>
            </div>
        </div>
    );
}


export default Main;