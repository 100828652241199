import './App.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import {Routes, Route, useLocation} from "react-router-dom";
import Header from "../Header/Header";
import Main from "../Main/Main";
import PricelistHorses from "../Cennik/PricelistHorses";
import Galeria from "../Galeria/Galeria";
import Kontakt from "../Kontakt/Kontakt";
import Dopasowanie from "../Dopasowanie/Dopasowanie";
import React, {useEffect, useState} from "react";
import ForHorse from "../Sklep/ForHorse";
import Ropes from "../Sklep/ropes/Ropes";
import Reins from "../Sklep/reins/Reins";
import Cordeo from "../Sklep/cordeo/Cordeo";
import Uwiaz from "../Sklep/uwiaz/Uwiaz";
import Halters from "../Sklep/halters/Halters";
import Basket from "../Sklep/basket/Basket";
import Checkout from "../Sklep/checkout/Checkout";
import Payment from "../Sklep/checkout/Payment";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import CookieDisclaimer from "../Cookies/CookieDisclaimer";
import Cookies from "js-cookie";
import Footer from "../Footer/Footer";
import {trackPageView, analyticsInitialise} from '../Utility/Analytics';
import Menu from "../Header/Menu/Menu";
import HeaderMini from "../Header/HeaderMini";
import ForDogs from "../Sklep/ForDogs";
import PricelistDogs from "../Cennik/PricelistDogs";
import LeashStandard from "../Sklep/leash/LeashStandard";
import ItemPreview from "../Admin/ItemPreview";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import PrivateRoute from "./PrivateRoute";

function App() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        analyticsInitialise();
    }, []);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });

        return () => unsubscribe();
    }, []);

    const location = useLocation();
    useEffect(() => {
        trackPageView(location.pathname);
    }, [location]);

    return (
        <div className="App">
            <Menu/>
            {location.pathname !== "/" ? <HeaderMini/> : <Header/>}
            <section className="main-content" id="printable-content">
                <Routes>
                    <Route exact path="/" element={<Main/>}/>
                    <Route exact path="/dlakonia/cennik" element={<PricelistHorses/>}/>
                    <Route exact path="/dlapsa/cennik" element={<PricelistDogs/>}/>
                    <Route exact path="/galeria" element={<Galeria name="gallery"/>}/>
                    <Route exact path="/kontakt" element={<Kontakt/>}/>
                    <Route exact path="/dopasowanie" element={<Dopasowanie/>}/>
                    <Route exact path="/paleta/liny" element={<Galeria name="ropes"/>}/>
                    <Route exact path="/paleta/sznurki" element={<Galeria name="halters"/>}/>

                    <Route exact path="/dlakonia" element={<ForHorse/>}/>
                    <Route exact path="/dlakonia/liny" element={<Ropes/>}/>
                    <Route exact path="/dlakonia/wodze" element={<Reins/>}/>
                    <Route exact path="/dlakonia/cordeo" element={<Cordeo/>}/>
                    <Route exact path="/dlakonia/uwiazy" element={<Uwiaz/>}/>
                    <Route exact path="/dlakonia/haltery" element={<Halters/>}/>

                    <Route exact path="/dlapsa" element={<ForDogs/>}/>
                    <Route exact path="/dlapsa/smycz-dynamiczna" element={<LeashStandard type="dynamic"/>}/>
                    <Route exact path="/dlapsa/smycz-statyczna" element={<LeashStandard type="static"/>}/>

                    <Route exact path="/koszyk" element={<Basket/>}/>
                    <Route exact path="/zamowienie" element={<Checkout/>}/>
                    <Route exact path="/zaplac" element={<Payment/>}/>
                    <Route exact path="/prywatnosc" element={<PrivacyPolicy/>}/>

                    <Route exact path="/paleta/12i14mm" element={<Galeria name="ropes"/>}/>
                    <Route exact path="/paleta/8mm" element={<Galeria name="halters"/>}/>
                    <Route exact path="/konfigurator" element={<ForHorse/>}/>
                    <Route exact path="/konfigurator/liny" element={<Ropes/>}/>
                    <Route exact path="/liny" element={<Ropes/>}/>
                    <Route exact path="/cennik" element={<PricelistHorses/>}/>
                    <Route exact path="/konfigurator/wodze" element={<Reins/>}/>
                    <Route exact path="/konfigurator/cordeo" element={<Cordeo/>}/>
                    <Route exact path="/konfigurator/uwiazy" element={<Uwiaz/>}/>
                    <Route exact path="/konfigurator/haltery" element={<Halters/>}/>

                    <Route path="/panel" element={<PrivateRoute />} />
                    {user && <Route path="/panel/details" element={<ItemPreview />} />}

                </Routes>
            </section>
            {Cookies.get('cookieConsent') !== 'accepted' ? <CookieDisclaimer/> : ""}
            <Footer/>
        </div>
    );
}

export default App;
