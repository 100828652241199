import trashIcn from "../../assets/trash.png";
import "./BasketItem.scss";
import ImagePreview from "./ImagePreview";

const BasketItem = ({itemId, imgIndex, title, price, removeHandler}) => {
    return (
        <div className="basket-item">
            <div className="item-image">
                <ImagePreview title={title} imgIndex={imgIndex} />
            </div>
            <div className="item-description">
                <p>{title}</p>
                <p></p>
            </div>
            <div className="item-price">
                <p>{price}<span>,00</span><span>zł</span></p>
            </div>
            {removeHandler ? <div className="item-remove">
                <button aria-label="Remove item" onClick={() => removeHandler(itemId)}>
                    <img src={trashIcn} alt="Usuń"/>
                </button>
            </div> : ""}
        </div>
    );
};

export default BasketItem;