import "./Footer.scss";
import {Link} from "react-router-dom";
import {trackClick} from "../Utility/Analytics";

const Footer = () => {

    const track = (type) => {
        trackClick("Social media link", type);
    }

    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="socials">
                    <h4>Polub nas lub napisz wiadomość!</h4>
                    <div className="social-media-buttons">
                        <Link onClick={() => track("Instagram")}
                              to="https://www.instagram.com/corinnyliny/" className="instagram"/>
                        <Link onClick={() => track("Facebook")}
                              to="https://www.facebook.com/corinnylinypl" className="facebook"/>
                        <Link onClick={() => track("Messenger")}
                              to="https://api.whatsapp.com/send?phone=%2B48602462444" className="whatsapp"/>
                        <Link  onClick={() => track("email")}
                            to="mailto:kontakt@corinnyliny.pl" className="email"/>
                    </div>
                    <div className="footer-menu"></div>
                </div>
                <div className="copyright">
                    <Link to="https://www.corinnyliny.pl/">CorinnyLiny</Link> - <Link
                    to="https://www.corinnyliny.pl/">www.corinnyliny.pl</Link> &copy; 2024
                </div>
            </div>
        </footer>
    );
}

export default Footer;