import LightGallery from "lightgallery/react";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import {useEffect, useState} from "react";
import {getDownloadURL, ref} from "firebase/storage";
import {firebaseStorage} from "../../firebase";
import {Link} from "react-router-dom";
import halterImg from "../../assets/halter.jpg";
import ImageSkeleton from "../../ImageSkeleton/ImageSkeleton";

const HaltersDescription = () => {

    const onInit = () => {
    };

    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchImages = async () => {
            // Array of filenames you want to download
            const filenames = ['corinnyliny22.jpg', 'corinnyliny23.jpg', 'corinnyliny24.jpg'];

            try {
                // Map through filenames to create references and get download URLs
                const downloadURLPromises = filenames.map(filename => {
                    const fileRef = ref(firebaseStorage, `gallery/${filename}`);
                    return getDownloadURL(fileRef);
                });

                let imageList = [];
                imageList.push(halterImg);
                imageList = [...imageList, ...await Promise.all(downloadURLPromises)];
                setImages(imageList);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching images: ", error);
                setLoading(false);
            }
        };

        fetchImages();
    }, []);

    return (
        <div>
            <div className="description box">
                <div className="description-content">
                    <h2>Halter</h2>
                    <p>
                        Zamawiając halter koniecznie określ wymiary, abyśmy mogli stworzyć dla Ciebie
                        kantar, który leży na głowie Twojego konia jak ulał! Szczególnie dokładnie należy zdjąć
                        <strong className="green"> zielony pomiar</strong> - powinien kończyć się dokładnie na linii ganasza!
                    </p>
                    <p>
                        Wszelkie instrukcje jak dokonać
                        pomiarów znajdują się <Link to="/dopasowanie">TUTAJ</Link>.
                    </p>
                </div>
                <div className="description-photos">
                    {loading ? (
                        <div className="lg-react-element">
                            {new Array(20).fill(0).map((_, index) => <ImageSkeleton key={index} isGrid={true}/>)}
                        </div>
                    ) : (
                        <LightGallery onInit={onInit} speed={500} plugins={[lgThumbnail, lgZoom]}>
                            {images.map((url, index) => (<a key={index} href={url}>
                                <img className="product-img" src={url} alt="Halter"/>
                            </a>))}
                        </LightGallery>)}
                </div>
            </div>
        </div>
    )
}

export default HaltersDescription;