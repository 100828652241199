const CheckoutSummary = ({items, totalPrice, deliveryCost}) => {

    return (
        <div>
            <div className="checkout-summary">
                <h3>Podsumowanie</h3>
                <table>
                    <thead>
                    <tr>
                        <th>Lp.</th>
                        <th>Nazwa produktu</th>
                        <th>Cena</th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map((item, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.productName}</td>
                            <td>{item.price},00 zł</td>
                        </tr>
                    ))}
                    <tr>
                        <td>{items.length + 1}</td>
                        <td>Koszt wysyłki</td>
                        <td>{deliveryCost},00 zł</td>
                    </tr>
                    <tr className="table-summary">
                        <td colSpan="2"><strong>Razem do zapłaty</strong></td>
                        <td><strong>{totalPrice + deliveryCost},00 zł</strong></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default CheckoutSummary;