import './Header.scss';
import {Link} from "react-router-dom";
import logo from "../assets/logo-inverted.png"

const HeaderMini = () => {

    return (
        <header className="header mini">
            <div className="header_content">
                <h1>
                    <Link to="/">
                        <img src={logo} alt="Corinny Liny" className="logo"/>
                    </Link>
                </h1>
            </div>
        </header>
    );
}

export default HeaderMini;