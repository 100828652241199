import "../Products.scss"
import Picker from "../../Galeria/Picker/Picker";
import {useState} from "react";
import HaltersDescription from "./HaltersDescription";
import {Link} from "react-router-dom";
import HalterModel from "./HalterModel";
import AddToBasket from "../basket/AddToBasket";
import {Helmet} from "react-helmet";

const Halters = () => {

    const [halter, setHalter] = useState(new HalterModel());
    const [price, setPrice] = useState(halter.calculatePrice());

    const handleThicknessChange = (e) => {
        halter.setThickness(e.target.value)
        if (price !== halter.calculatePrice()) {
            setPrice(halter.calculatePrice());
        }
    };

    const handleRopeColorChange = (index) => {
        halter.setColorIndex(index);
    }

    const handleAddonChange = e => {
        const id = e.target.id;

        if (id === "braid") {
            halter.toggleBraidOnTheNoseband();
        }

        setPrice(halter.calculatePrice());
    }

    const handleSizeChange = e => {
        const id = e.target.id;
        const value = e.target.value;
        let updatedHalter = new HalterModel(halter);

        if (id === "blue") {
            updatedHalter.setBlueSize(value);
        } else if (id === "green") {
            updatedHalter.setGreenSize(value);
        } else if (id === "red") {
            updatedHalter.setRedSize(value);
        } else if (id === "yellow") {
            updatedHalter.setYellowSize(value);
        }

        setHalter(updatedHalter);
    }

    return (
        <div className="content product">
            <Helmet>
                <title>Halter, kantar sznurkowy, szytwny i miękki</title>
                <meta name="description"
                      content="Halter, kantar sznurkowy na indywidualne zamówienie, wysokiej jakości i różnej twardości"/>
                <link rel="canonical" href="https://corinnyliny.pl/dlakonia/haltery"/>
            </Helmet>
            <div className="column column-left">
                <HaltersDescription/>
                <div className="thickness box">
                    <p>Wybierz grubość:</p>
                    <div className="select-picker mobile-only">
                        <select id="thicknessSelect" onChange={handleThicknessChange}>
                            <option value="8" defaultChecked="true">8mm</option>
                            <option value="6" onChange={handleThicknessChange}>6mm</option>
                        </select>
                    </div>
                    <div className="radio-picker">
                        <label>
                            <input type="radio" id="8mm" value="8" name="thickness" defaultChecked="true"
                                   onChange={handleThicknessChange}/>
                            <span>8mm</span>
                        </label>

                        <label>
                            <input type="radio" id="6mm" value="6" name="thickness" onChange={handleThicknessChange}/>
                            <span>6mm</span>
                        </label>
                    </div>
                </div>
                <div className="rope-addons box">
                    <p>Wybierz elementy opcjonalne:</p>
                    <label>
                        <input type="checkbox" id="braid" onChange={handleAddonChange} value="braid" name="addon"/>
                        <span>Oplot na nachrapniku</span>
                    </label>
                </div>
                <div className="halter-size box">
                    <p>Wymiary w centymetrach <Link className="standard-link" to="/dopasowanie"> (jak zmierzyć?)</Link>:
                    </p>
                    <label>
                        <span>Niebieski pomiar: </span>
                        <input type="text" id="blue"
                               onChange={handleSizeChange}
                               value={halter.getBlueSize()}
                               placeholder="Podaj wymiar..."
                               name="halter-size"/>
                    </label>
                    <label>
                        <span>Czerwony pomiar: </span>
                        <input type="text"
                               onChange={handleSizeChange}
                               value={halter.getRedSize()}
                               id="red" placeholder="Podaj wymiar..." name="halter-size"/>
                    </label>
                    <label>
                        <span>Zielony pomiar: </span>
                        <input type="text" id="green"
                               onChange={handleSizeChange}
                               value={halter.getGreenSize()}
                               placeholder="Podaj wymiar..." name="halter-size"/>
                    </label>
                    <label>
                        <span>Żółty pomiar: </span>
                        <input type="text" id="yellow"
                               onChange={handleSizeChange}
                               value={halter.getYellowSize()}
                               placeholder="Podaj wymiar..."
                               name="halter-size"/>
                    </label>
                </div>
            </div>
            <div className="column column-right">
                <div className="rope-color">
                    <Picker name="halters" handler={handleRopeColorChange}/>
                </div>
                <AddToBasket item={halter}/>
            </div>
        </div>
    );
}

export default Halters;