import "./Sklep.scss";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

const ForHorse = () => {
    return (
        <div className="content shop">
            <Helmet>
                <title>Sklep i konfigurator produktów - Corinny Liny</title>
                <meta name="description" content="Liny do pracy z ziemi, wodze linowe, cordeo, uwiązy i haltery - wszystko to Corinna robi ręcznie." />
                <link rel="canonical" href="https://corinnyliny.pl/dlakonia"/>
            </Helmet>
            <Link className="shop-box halter" to={"/dlakonia/haltery/"}><p>Haltery</p></Link>
            <Link className="shop-box wodze"  to={"/dlakonia/wodze/"}><p>Wodze linowe</p></Link>
            <Link className="shop-box liny" to={"/dlakonia/liny/"}><p>Liny do pracy ziemi</p></Link>
            <Link className="shop-box uwiaz" to={"/dlakonia/uwiazy/"}><p>Uwiązy</p></Link>
            <Link className="shop-box cordeo" to={"/dlakonia/cordeo/"}><p>Cordeo</p></Link>
            <Link className="shop-box pricelist" to={"/dlakonia/cennik/"}><p>sprawdź cennik</p></Link>
        </div>
    );

}

export default ForHorse;