import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import LightGallery from "lightgallery/react";
import React from "react";
import "./Dopasowanie.css";
import {Helmet} from "react-helmet";

function Dopasowanie() {
    const onInit = () => {
    };

    return (
        <div className="dopasowanie-container">
            <Helmet>
                <title>Jak dopasować halter - CorinnyLiny</title>
                <meta name="description" content="Dopasowanie sprzetu dla konia: halter, wymiary" />
            </Helmet>
            <section>
                <h1>Dopasowanie haltera</h1>
                <p>Nasze haltery robione są indywidualnie, według miary zdjętej z konia, na podstawie wymiarów
                    przestawionych
                    na zdjęciu poniżej:</p>
                <LightGallery
                    onInit={onInit}
                    speed={500}
                    plugins={[lgThumbnail, lgZoom]}
                >
                    <a href="/dopasowanie.jpg">
                        <img alt="CorinnyLiny.pl" src="/dopasowanie.jpg"/>
                    </a>

                </LightGallery>
                <p>Białe punkty - miejsca, gdzie leżą węzełki</p>
                <p><strong className="blue-strong">Niebieski pomiar</strong> - odległość między prawym i lewym, białym punktem, na którym leży węzełek, te
                    punkty znajdują się  w odległości 2 cm od końca grzebienia twarzowego (wystająca kostka) w kierunku chrap </p>
                <p><strong className="red-strong">Czerwony pomiar</strong> - obwód pyska mierzony na tym samym poziomie, co niebieski pomiar.</p>
                <p><strong className="green-strong">Zielony pomiar</strong> - linia łącząca punkty,
                    w których powinny znajdować się węzełki, kończy się za ganaszem, kilka cm poniżej nasady ucha </p>
                <p><strong className="yellow-strong">Żółty pomiar</strong> - obwód szyi mierzony tuż za uszami i za linią ganaszy</p>

            </section>
        </div>
    );
}

export default Dopasowanie;