import "./Payment.scss"
import {useLocation} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import miniLogo from "../../assets/checkmark.png";
import {trackPurchaseConversion} from "../../Utility/Analytics";

const Payment = () => {

    const [orderId, setOrderId] = useState(null);

    const location = useLocation();
    const {order} = location.state || {};
    const fetchStatusRef = useRef({ wasTriggered: false });

    useEffect(() => {
        if (!fetchStatusRef.current.wasTriggered) {
            fetchStatusRef.current.wasTriggered = true;

            if (localStorage.getItem('basket')) {
                trackPurchaseConversion(order.basket.productName,order.totalPrice);

                const registerTransaction = async () => {
                    try {
                        const response = await fetch('https://us-central1-corinnyliny.cloudfunctions.net/submitOrder', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(order),
                        });

                        const data = await response.json();
                        return data.orderId;
                    } catch (error) {
                        console.error('Error submitting order:', error);
                    }
                };

                registerTransaction().then(orderId => {
                    clearBasket();
                    setOrderId(orderId);
                });
            } else {
                setOrderId(-1);
            }
        }
    }, [order]);


    const clearBasket = () => {
        localStorage.removeItem("basket")
        window.dispatchEvent(new CustomEvent('basketUpdated'));
    }

    const error = (
        <div className="content orderError">
            <h2>Wystąpił błąd!</h2>

            <p>
                Niestety podczas generowania zamówienia wystąpił błąd. Wróć do koszyka i spróbuj zrealizować zamówienie
                ponownie.
            </p>
            <p>
                Jeśli problem się powtarza prosimy o kontakt poprzez e-mail: <a
                href="mailto:kontakt@corinnyliny.pl">kontakt@corinnyliny.pl</a>
            </p>
        </div>
    );

    const loadingDiv = (
        <div className="loading">
            <p>Trwa generowanie zamówienia...</p>
            <img className="imgLoading" src={miniLogo} alt="Proszę czekać..."/>
        </div>
    );

    function getNextHourDate() {
        let now = new Date();
        let twoDaysFromNow = new Date(now.getTime() + 3 * 24 * 60 * 60 * 1000); // 2 days from now

        const day = twoDaysFromNow.getDate().toString().padStart(2, '0');
        const month = (twoDaysFromNow.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
        const year = twoDaysFromNow.getFullYear().toString().substr(-2);

        return `${day}/${month}/20${year}`;
    }

    const paymentInfo = (
        <div className="content payment-details">
            <div className="order-id-info">
                <h2>Twoje zamówienie zostało wygenerowane.</h2>
                <p>Numer zamówienia: <span>{orderId}</span></p>
                <p>Wartość zamówienia: <span>{order ? order.totalPrice : 0}</span><small>,00 zł</small></p>
                <p>Czas realizacji zamówienia: <span>2-21 dni (średnio: 7)</span></p>
                <p>Status: <span>przyjęte do realizacji</span></p>
                <p>Niebawem skontaktuję się z Panią/Panem w celu omówienia szczegółów zamówienia:
                    <span> przekazania danych do płatności</span> oraz <span>ustalenia adresu wysyłki</span>.</p>

            </div>
            <div className="payments">
                <h2>Informacje o płatności</h2>
                <p>
                    Dane do zrobienia przelewu zostaną niebawem Pani/Panu dostarczone na podane dane kontaktowe
                    (e-mail i/lub SMSem).
                </p>
                <p>
                    Prosimy o opłacenie zamówienia do {getNextHourDate()} za pomocą przelewu na telefon BLIK lub
                    standardowego przelewu na konto bankowe na dane przekazane e-mailem/SMSem.
                </p>
            </div>
        </div>
    );

    const orderInfo = (
        <div className="content order-info">
            {orderId ? paymentInfo : loadingDiv}
        </div>
    );


    const isValidated = order && (order.totalPrice && order.basket && order.isPolicyAccepted && (order.email || order.phone));

    return isValidated ? orderInfo : error;
}

export default Payment;