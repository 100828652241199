import React, {useEffect, useRef} from 'react';

const InpostGeowidget = ({
                             token = "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJzQlpXVzFNZzVlQnpDYU1XU3JvTlBjRWFveFpXcW9Ua2FuZVB3X291LWxvIn0.eyJleHAiOjIwMzIxNTY2ODIsImlhdCI6MTcxNjc5NjY4MiwianRpIjoiODNjMzA4OWYtZmMwNi00MDFiLTkwNTctMmE2OWQ3NGY3ZGQzIiwiaXNzIjoiaHR0cHM6Ly9sb2dpbi5pbnBvc3QucGwvYXV0aC9yZWFsbXMvZXh0ZXJuYWwiLCJzdWIiOiJmOjEyNDc1MDUxLTFjMDMtNGU1OS1iYTBjLTJiNDU2OTVlZjUzNTp6OFpBYlZfVGp2WjhPVEx2dkhEdkpDeGVOSmRPRmFTRmhkSUM5ZG8zTHBJIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoic2hpcHgiLCJzZXNzaW9uX3N0YXRlIjoiMjY2YzU4MTUtMGMyNi00OWYwLWFkZjktY2M1OWQ4YTIzYjFkIiwic2NvcGUiOiJvcGVuaWQgYXBpOmFwaXBvaW50cyIsInNpZCI6IjI2NmM1ODE1LTBjMjYtNDlmMC1hZGY5LWNjNTlkOGEyM2IxZCIsImFsbG93ZWRfcmVmZXJyZXJzIjoiY29yaW5ueWxpbnkucGwiLCJ1dWlkIjoiNjBkMTAyMGYtNTBmNC00OTk0LTk4MzktOTgxYWYzZjY0OGU3In0.EynXVRHAGUTfYb8xpqbIMAfXS9mze_9m9-8hDCyoOuHfIj1EMrjj6vEpNdcLSAMvSlmRwbMje7VFnjUWBmNnzWc9uqU-LiEQjrGmLknpkJ2Jl7tQm-_Tq-UVHJFOh7fwCXfXCcdEVxpRO_lJ5j7RlzSImlR-il7JueMTAZY07BMK2MzDSoagAv49i1rJ32nY0ePrfeylHA62mbqarr7jO9G8qX7dIYXI6mwlWNniSTIqH6dfKfensmfZhg4M7tk7lgSRCn5YhMa5OQ-ZTRGKlaQ6hlVPtzoI_0cfUIcga1CTOH9ZBJXCmRTZptJh2xVUePMHOd1_MGdCXb0CqVBZAw",
                             language = 'pl',
                             config = 'parcelcollect',
                             callback
                         }) => {
    const widgetRef = useRef(null);

    useEffect(() => {
        // Load the CSS file for the Geowidget
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://geowidget.inpost.pl/inpost-geowidget.css';
        document.head.appendChild(link);

        // Load the JS file for the Geowidget
        const script = document.createElement('script');
        script.src = 'https://geowidget.inpost.pl/inpost-geowidget.js';
        script.defer = true;
        document.body.appendChild(script);

        script.onload = () => {
            // Add event listener for onpointselect
            const handlePointSelect = (event) => {
                if (callback) {
                    callback(event.detail.name);
                }
            };

            document.addEventListener('onpointselect', handlePointSelect);

            // Clean up function
            return () => {
                document.removeEventListener('onpointselect', handlePointSelect);
                document.head.removeChild(link);
                document.body.removeChild(script);
            };
        };
    }, [callback]);

    return (
        <div ref={widgetRef}>
            <inpost-geowidget
                token={token}
                language={language}
                onpoint="onpointselect"
                config={config}/>
        </div>
    );
};

export default InpostGeowidget;
