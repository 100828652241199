import React, {useEffect, useState} from 'react';
import './Header.scss';
import {Link} from "react-router-dom";
import logo from "../assets/logo-inverted.png"

const Header = () => {

    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = (e) => {
            const position = window.pageYOffset;
            setScrollY(position);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const headerStyle = {
        transform: `translateY(${scrollY  - scrollY * 2}px)`,
        opacity: `${1 - (scrollY * 0.004)}`
    };

    return (
        <header className="header">
            <div className="header_content" style={headerStyle}>
                <h1>
                    <Link to="/">
                        <img src={logo} alt="Corinny Liny" className="logo"/>
                    </Link>
                </h1>
            </div>
        </header>
    );
}

export default Header;