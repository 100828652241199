import "../Products.scss"
import Picker from "../../Galeria/Picker/Picker";
import {useState} from "react";
import UwiazModel from "./UwiazModel";
import UwiazDescription from "./UwiazDescription";
import AddToBasket from "../basket/AddToBasket";
import {Helmet} from "react-helmet";

const Uwiaz = () => {

    const [uwiaz] = useState(new UwiazModel());
    const [price, setPrice] = useState(uwiaz.calculatePrice());

    const handleColorChange = (index) => {
        uwiaz.setColorIndex(index);
        if (price !== uwiaz.calculatePrice()) {
            setPrice(uwiaz.calculatePrice());
        }
    }

    const handleAddonChange = () => {
        uwiaz.toggleCarabiner();
        if (price !== uwiaz.calculatePrice()) {
            setPrice(uwiaz.calculatePrice());
        }
    }

    return (
        <div className="content product">
            <Helmet>
                <title>Uwiąz dla konia</title>
                <meta name="description"
                      content="Wysokiej jakości uwiąż dla konia na indywidualne zamówienie"/>
                <link rel="canonical" href="https://corinnyliny.pl/dlakonia/uwiazy"/>
            </Helmet>
            <div className="column column-left">
                <UwiazDescription/>
                <div className="rope-addons box">
                    <p>Rodzaj wykończenia:</p>
                    <label>
                        <input type="checkbox" id="carabineer" value="carabiner-type" onChange={handleAddonChange}
                               name="addonType"/>
                        <span>Indywidualnie dobrany karabinek</span>
                    </label>
                </div>
            </div>
            <div className="column column-right">
                <div className="rope-color">
                    <Picker name="ropes" handler={handleColorChange}/>
                </div>
                <AddToBasket item={uwiaz}/>
            </div>
        </div>
    );
}
export default Uwiaz;