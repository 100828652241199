import {Helmet} from "react-helmet";
import Picker from "../../Galeria/Picker/Picker";
import AddToBasket from "../basket/AddToBasket";
import {useState} from "react";
import LeashModel from "./LeashModel";
import LightGallery from "lightgallery/react";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import smyczDynamicznaJpg from "../../assets/smycz_blue.jpg";
import smyczStatycznaJpg from "../../assets/smycz_red.jpg";

const LeashStandard = (props) => {

    const [leash] = useState(new LeashModel(props.type));
    const [price, setPrice] = useState(leash.calculatePrice());

    const onInit = () => {

    }

    const handleAddonChange = (e) => {
        const addon = e.target.id;

        if (addon === "customCarabiner") {
            leash.toggleCarabiner();
        } else if (addon === "graver") {
            leash.toggleGraver();
        } else if (addon === "art-popper") {
            leash.toggleDogName();
        }

        setPrice(leash.calculatePrice());
    }

    const handleLengthChange = (e) => {
        leash.setLength(e.target.value);
        const newPrice = leash.calculatePrice();
        if (price !== newPrice) {
            setPrice(newPrice);
        }
    }

    const handleLeashColorChange = (index) => {
        leash.setColorIndex(index);
    }

    return (
        <div className="content product">
            <Helmet>
                <title>{leash.productName} - Corinny Liny</title>
                <meta name="description"
                      content="Ręcznie wytwarzana wodza linowa jeździecka na indywidualne zamówienie, jeździectwo"/>
                <link rel="canonical" href="https://corinnyliny.pl/dlapsa"/>
            </Helmet>
            <div className="column column-left">
                <div className="description box">
                    <div className="description-content">
                        <h2>{leash.productName}</h2>
                        <p>
                            {leash.type.description}
                        </p>
                    </div>
                    <div className="description-photos">
                        <LightGallery onInit={onInit} speed={500} plugins={[lgThumbnail, lgZoom]}>
                            <a href={props.type === "static" ? smyczStatycznaJpg : smyczDynamicznaJpg}>
                                <img className="product-img"
                                     src={props.type === "static" ? smyczStatycznaJpg : smyczDynamicznaJpg}
                                     alt={leash.productName}/>
                            </a>
                        </LightGallery>
                    </div>
                </div>
                <div className="rope-length box">
                    <p>Wybierz długość:</p>
                    <div className="select-picker mobile-only">
                        <select id="ropeLengthSelect" onChange={handleLengthChange}>
                            <option value="300">3m</option>
                            <option value="400">4m</option>
                            <option value="500">5m</option>
                            <option value="600">6m</option>
                        </select>
                    </div>
                    <div className="radio-picker">
                        <label>
                            <input type="radio" id="3m" value="300" onChange={handleLengthChange}
                                   defaultChecked="true"
                                   name="rope-length"/>
                            <span>3m</span>
                        </label>

                        <label>
                            <input type="radio" id="4m" value="400" name="rope-length"
                                   onChange={handleLengthChange}/>
                            <span>4m</span>
                        </label>

                        <label>
                            <input type="radio" id="5m" value="500" name="rope-length"
                                   onChange={handleLengthChange}/>
                            <span>5m</span>
                        </label>

                        <label>
                            <input type="radio" id="6" value="600" name="rope-length"
                                   onChange={handleLengthChange}/>
                            <span>6m</span>
                        </label>
                    </div>
                </div>

                <div className="rope-addons box">
                    <p>Wybierz elementy opcjonalne:</p>
                    <label>
                        <input type="checkbox" id="customCarabiner" onChange={handleAddonChange} value="carabiner"
                               name="addon"/>
                        <span>Inny typ karabińczyka</span>
                    </label>

                    <label>
                        <input type="checkbox" id="graver" onChange={handleAddonChange} value="graver" name="addon"/>
                        <span>Skórzana metka z napisem/imieniem psa</span>
                    </label>

                    <label>
                        <input type="checkbox" id="art-popper" onChange={handleAddonChange} value="art-popper"
                               name="addon"/>
                        <span>Ozdobne tłoczenia na skórzanych elementach</span>
                    </label>
                </div>
            </div>
            <div className="column column-right">
                <div className="rope-color">
                    <Picker name="leashes" type={props.type} handler={handleLeashColorChange}/>
                </div>
                <AddToBasket item={leash}/>
            </div>
        </div>
    );
}

export default LeashStandard;