import "./OrderBasket.scss"
import {CARABINER, POPPER} from "./TRANSLATIONS";
import ImagePreview from "../Sklep/basket/ImagePreview";

function OrderBasket({basket}) {
    return (
        <div className="order-basket-list">
            {basket.map(item => (
                <div className="order-item" key={item.price + item.colorIndex}>
                    <ImagePreview title={item.productName} imgIndex={item.colorIndex} />
                    <div className="order-item-base-info">
                        <p className="order-item-product-name">{item.productName} {item.braidOnTheNoseband ? "z oplotem" : ""}</p>
                        <table className="order-item-table">
                            <tbody>
                            <tr>
                                <th>Cena: {item.price}<small>,00</small>zł</th>
                                <th>Wzór: {item.colorIndex + 1}</th>
                                <th>Grubość: {item.thickness}mm</th>
                                {item.length ? (
                                    <>
                                        <th>Długość: {item.length / 100}m</th>
                                    </>
                                ) : ""}
                            </tr>
                            </tbody>
                        </table>
                        <div className="order-item-additional-info">
                            {(item.popperType !== "none" && item.popperType) && (
                                <div className="item-detail-content">
                                    <h6>Popper {POPPER[item.popperType]}</h6>
                                    {(item.popperDetails|| item.popperGraver) && (<p><strong>Wzór:</strong> {item.popperGraver}{item.popperDetails}</p>)}
                                </div>
                            )}
                            {(item.carabiner) && (
                                <div className="item-detail-content">
                                    <h6>{item.carabiner === "none" ? "Bez karabinka" : `Karabinek ${CARABINER[item.carabiner]}`}</h6>
                                </div>
                            )}
                            {(item.productName === "Halter") && (
                                <div className="item-detail-content">
                                    <h6>Wymiary</h6>
                                    <div className="row">
                                        <p className="blue">Niebieski: {item.blueSize}cm</p>
                                        <p className="red">Czerowny: {item.redSize}cm</p>
                                        <p className="green">Zielony: {item.greenSize}cm</p>
                                        <p className="yellow">Żółty: {item.yellowSize}cm</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default OrderBasket;