export const CARABINER = {
  none: "brak",
  standard: "standardowy",
  custom: "indywidualny"
};

export const POPPER = {
    none: "brak",
    plain: "pusty",
    graver: "z grawerem",
    individual: "indywidualny"
}