import LightGallery from "lightgallery/react";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import {useEffect, useState} from "react";
import {getDownloadURL, ref} from "firebase/storage";
import {firebaseStorage} from "../../firebase";
import ImageSkeleton from "../../ImageSkeleton/ImageSkeleton";

const ReinsDescription = () => {

    const onInit = () => {
    };

    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchImages = async () => {
            // Array of filenames you want to download
            const filenames = ['corinnyliny5.jpg'];

            try {
                // Map through filenames to create references and get download URLs
                const downloadURLPromises = filenames.map(filename => {
                    const fileRef = ref(firebaseStorage, `gallery/${filename}`);
                    return getDownloadURL(fileRef);
                });

                const imageList = await Promise.all(downloadURLPromises);
                setImages(imageList);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching images: ", error);
                setLoading(false);
            }
        };

        fetchImages();
    }, []);

    return (
        <div>
            <div className="description box">
                <div className="description-content">
                    <h2>Wodze linowe</h2>
                    <p>Wodze linowe służą do precyzyjnego prowadzenia konia podczas jazdy, zapewniając lepszą kontrolę
                        i komfort zarówno dla jeźdźca, jak i konia.</p>
                    <p>
                        Wodze standardowo wykończone są srebrnymi karabińczykami szczypcowymi, które za dopłatą można wymienić na inne.
                    </p>
                </div>
                <div className="description-photos">
                    {loading ? (
                        <div className="lg-react-element">
                            {new Array(20).fill(0).map((_, index) => <ImageSkeleton key={index} isGrid={true}/>)}
                        </div>
                    ) : (
                    <LightGallery onInit={onInit} speed={500} plugins={[lgThumbnail, lgZoom]}>
                        {images.map((url, index) => (<a key={index} href={url}>
                            <img className="product-img" src={url} alt="Wodza linowa zamknięta"/>
                        </a>))}
                    </LightGallery>)}
                </div>
            </div>
        </div>
    )
}

export default ReinsDescription;