import "./Sklep.scss";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

const ForDogs = () => {
    return (
        <div className="content shop">
            <Helmet>
                <title>Smycze, obroże, ringówki - Corinny Liny</title>
                <meta name="description" content="Smycze statyczne, dynamiczne, ringówki, obroże dla psów" />
                <link rel="canonical" href="https://corinnyliny.pl/dlapsa"/>
            </Helmet>
            <Link className="shop-box smycz_blue" to={"/dlapsa/smycz-dynamiczna/"}><p>Smycze dynamiczne</p></Link>
            <Link className="shop-box smycz_red"  to={"/dlapsa/smycz-statyczna/"}><p>Smycze statyczne</p></Link>
            {/*<Link className="shop-box smycz_blue"  to={"/dlapsa/smycz-ringowka/"}><p>Smycze ringówki</p></Link>*/}
            {/*<Link className="shop-box obroza" to={"/dlapsa/obroze"}><p>Obroże</p></Link>*/}
            <Link className="shop-box pricelist" to={"/dlapsa/cennik/"}><p>sprawdź cennik</p></Link>
        </div>
    );

}

export default ForDogs;